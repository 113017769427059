import { Form, Field, reduxForm, reset } from "redux-form";
import { CardBody, Card, Button } from "reactstrap";
import RenderField from "../../form-components/RenderField";
import axios from "axios";
import requests from "../../utils/Requests";
const MakePayment = (props) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  const mkpayment = (values) => {
    const submitData = {
      paymentAgencyId: props.idForReport,
      paymentAmount: values.paymentAmount,
    };
    axios
      .post(requests.getAgencyPaymentUrl, submitData)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Form onSubmit={handleSubmit(mkpayment.bind(this))}>
        <Field
          name="paymentAmount"
          type="text"
          component={RenderField.RenderTextField}
        />
        <br />
        <div className="float-right">
          <Button color="primary" disabled={submitting}>
            Pay now
          </Button>{" "}
          <Button
            color="danger"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Cancel
          </Button>{" "}
          {/* <Button color="danger" onClick={history.goBack()}>
                Cancel
              </Button>{" "} */}
        </div>
      </Form>
    </>
  );
};

export default reduxForm({
  form: "MakePayment",

  // onSubmitSuccess: AfterSubmit,
})(MakePayment);
