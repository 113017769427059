
export const actone = () => ({ type: "ACT_ONE" });
export const acttwo = () => ({ type: "ACT_TWO" });
export const otppage = () => ({ type: "OTP_PAGE" });
export const testPayload = () => ({ type: "TestPayload" });

export const myreducer = (state, actions) => {
  switch (actions.type) {
    case "ACT_ONE":
      return (state = 1);
    case "ACT_TWO":
      return (state = 2);
    case "OTP_PAGE":
      return (state = 777);
    case "TestPayload":
      return { state, login: actions.TestPayload };
    default:
      return (state = 0);
  }
};
