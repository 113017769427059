import React, { useEffect, useRef } from "react";
// import "./styles.css";
// import video from "../../../../public/assets/"

const VideoPlayer = () => {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <div className="App1" >
      {/* <h1>Autoplay example</h1> */}
      <div>
      {/* <iframe
                id="player"
                type="text/html"
                // width="853px"
                // height="480px"
                width='500px'
                height="280px"
                src="https://www.youtube.com/embed/f5b9cc28-613e-4274-a59e-a3e3ccd748df?autoplay=1&mute=1&enablejsapi=1&loop=1&controls=0"
                // src="https://www.youtube.com/embed/f5b9cc28-613e-4274-a59e-a3e3ccd748df?autoplay=1&mute=1&enablejsapi=1&loop=1&controls=0&origin=https://hunjratourism.com/"
                frameborder="0"
                allowFullScreen
                muted
                autoPlay
                loop
              ></iframe> */}
        <video
          style={{ maxWidth: "100%", width: "500px", marginLeft: '90%', marginTop: '-30%'  }}
          playsInline
          loop
          muted
          controls
          // type="video/mp4"
          autoplay=''
          alt="All the devices"
          src='/home/rider/STUDY/GOPI/video.mp4'
          // src="https://www.youtube.com/8f026dbb-7538-476b-b8a6-41c8e9c47423?autoplay=1&mute=1&enablejsapi=1&loop=1&playlist=DPtf53CoPDA&controls=0&origin=https://hunjratourism.com/"
          // src="https://stream.mux.com/6fiGM5ChLz8T66ZZiuzk1KZuIKX8zJz00/medium.mp4"
          // src="http://199.192.31.213:8080/filestorage/video.mp4"
          ref={videoEl}
        />
          {/* <source src='../../../../public/assets/video.mp4' type="video/mp4" />
          </video>  */}
          {/* <video width="750" height="500" controls >
      <source src="../../../../public/assets/video.mp4" type="video/mp4"/>
</video> */}
      </div>
    </div>
  );
}
export default VideoPlayer;