import { Link } from "react-router-dom";
import requests from "../../utils/Requests";
import {Row, Col} from 'reactstrap'
import "react-multi-carousel/lib/styles.css";
import StarRating from "../../common/StarRating"
const TopDestination = ({ attData, login }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div
        className="holiday-plan-area tp-holiday-plan-area mg-top-96"
        style={{
          backgroundImage: "url(" + publicUrl + "assets/img/bg/8.png)",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-9">
              <div className="section-title text-center">
                <h2
                  className="title wow animated fadeInUp"
                  data-wow-duration="0.6s"
                  data-wow-delay="0.1s"
                >
                  Top Destination
                </h2>
                <p
                  className="wow animated fadeInUp"
                  data-wow-duration="0.6s"
                  data-wow-delay="0.2s"
                >
                  No vis fastidii accumsan, ignota postulant ea mea. Vis et
                  prima integre, ei vis ridens moderatius reformidans cu vim
                  doctus accumsan ignota.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            {attData.map((attraction, index) => (
              <div className="col-lg-3 col-sm-6">
                <div
                  className="single-destinations-list style-two wow animated fadeInUp single-destinations-list text-center"
                  data-wow-duration="0.4s"
                  data-wow-delay="0.1s"
                >
                  <div className="thumb">
                    <img
                      // src={publicUrl + "assets/img/destination-list/4.png"}
                      src={requests.imgpath + attraction.attThumbnailImage}
                      alt="list"
                    />
                  </div>
                  <div className="details">
                    <p className="location">
                      <img
                        src={publicUrl + "assets/img/icons/1.png"}
                        alt="map"
                      />
                      Dubai
                    </p>
                    <Link to={`tour-pack-details/${attraction.attractionsId}`}>
                      <h4 className="title">{attraction.attName}</h4>
                    </Link>
                    {/* <p className="content">7 Days Tour on 2 person</p> */}

                    <div className="tp-price-meta tp-price-meta-cl">
                      {/* <p>Price</p>
                      <h2>
                        {login == 1
                          ? attraction.b2bAdultPrice
                          : attraction.b2cAdultPrice}
                        &nbsp;&nbsp;&nbsp; <small>AED</small>
                      </h2> */}
<Row style={{marginLeft: '0%',}}>
<Col><p><div className="priceColor">Adult Price From</div> <h2>{attraction.adultPrice}&nbsp;&nbsp;&nbsp;<small id='attractionAed'>AED</small></h2></p></Col>
                        <Col><p ><div className="priceColor">Child Price From</div><br/><h2>{attraction.childPrice}&nbsp;&nbsp;&nbsp;<small id='attractionAed'>AED</small></h2></p> </Col>
                      </Row>
                      <div style={{marginLeft: '5%'}}>
                      <StarRating />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TopDestination;
