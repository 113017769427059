import Packdetail from "../tour-pack-detail/pack_details";

const TourPackDetail = () => {
  return (
    <>
      <Packdetail />
    </>
  );
};

export default TourPackDetail;
