import { useEffect, useState } from "react";
import encryptStorage from "../utils/Encryptstorage";
import CommonBanner from "./global-components/common_banner";
import Swal from "sweetalert2";
import axios from "axios";
import { Alert, Col, Label, Row } from "reactstrap";
import requests from "../utils/Requests";
import Subscribe from "./global-components/subscribe";
import Footer from "./global-components/footer";
import TopNav from "./global-components/top_nav";
import { FILE_STORE_BASE_URL } from '../utils/constants'
const Paymentconfirmation = () => {
  const [bookingState, setbookingState] = useState("Please Wait");
  const [bookdetails, setbookdetails] = useState([]);
  const [paymentPortel, setpaymentPortel] = useState("");
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [paymentTransactionId, setpaymentTransactionId] = useState("");
  const [showAlert, setshowAlert] = useState(0);
  const [finalRes, setfinalRes] = useState([]);

  let url = "",
    urlObject = "",
    obj = "",
    bookingObject = "",
    firstSubmitForBooking = "",
    submitObj = "",
    suckData = "";
  useEffect(() => {
    // let test =JSON.parse(tktmethod);
    // console.log("ksjndksjnkjsnds",test);

    // console.log("ldnfvcoldjvnfcsznv86748647864786",encryptStorage.getItem("firstSubmitForBooking"))

    // console.log("submitObj", firstSubmitForBooking);

    setTimeout(function () {
      // console.log("2222222222");
      // console.log("ppppppppppppppppppppppppp");
      url = encryptStorage.getItem("url"); //obj
      urlObject = JSON.parse(url);

      console.log("sdsdsfsfsfsf    urloj", urlObject);

      setpaymentTransactionId(urlObject.paymentTransactionId);

      obj = encryptStorage.getItem("DFuck");
      bookingObject = JSON.parse(obj);

      console.log("sdsdsfsfsfsf    bookingObject", bookingObject);

      setbookdetails(bookingObject);

      suckData = encryptStorage.getItem("suckapi");
      //  console.log("local Storage", suckData);
      submitObj = JSON.parse(suckData);
      // console.log("74674564564574r57", submitObj)

      if (submitObj.OpenTickets == 2) {
        // console.log("APi Ticket")

        const firstSubmitForBooking = encryptStorage.getItem(
          "firstSubmitForBooking"
        ); //obj
        let submitObjapiTicket = JSON.parse(firstSubmitForBooking);
        console.log("submitObjapiTicket", submitObjapiTicket);

        let urltwo = encryptStorage.getItem("urlTwo");
        let apidata = JSON.parse(urltwo);

        // Note:-  Here need to send post request to saveBurjTicket for save burj kalifa ticket and
        // second call also make automatically to confirm API ticket booking confirmed.

        const bookTicket = {
          attractionId: submitObjapiTicket.attractionId,
          agencyId: submitObjapiTicket.agencyId,
          agencyUserId: submitObjapiTicket.agencyUserId,
          bookB2cId: submitObjapiTicket.bookB2cId,
          customerName: submitObjapiTicket.customerName,
          customerEmail: submitObjapiTicket.customerEmail,
          customerMobileNumber: bookingObject.bookMobileNumber,
          eventId: submitObjapiTicket.eventId,
          eventName: submitObjapiTicket.eventName,
          eventTypeId: submitObjapiTicket.eventTypeId,
          resourceId: submitObjapiTicket.resourceId,
          startDateTime: submitObjapiTicket.startDateTime,
          endDateTime: submitObjapiTicket.endDateTime,
          available: submitObjapiTicket.available,
          status: submitObjapiTicket.status,
          nofAdult: submitObjapiTicket.nofAdult,
          nofChild: submitObjapiTicket.nofChild,
          nofInfant: submitObjapiTicket.nofInfant,
          adultPrice: submitObjapiTicket.adultPrice,
          childPrice: submitObjapiTicket.childPrice,
          bookingId: apidata.bookingId,
          invoiceNo: " ",
          totalPrice: submitObjapiTicket.totalPrice,
          bookPaymentMode: submitObjapiTicket.bookPaymentMode,
          bookPaymentRefId: urlObject.paymentTransactionId,
          ticketTypeId: bookingObject.ticketTypeId,
          bookingAddon: bookingObject.bookingAddon,
        };

        // console.log("Api Obj", bookTicket)

        axios
          .post(requests.getPaymentConfirmationBurjKhalifa, bookTicket)
          .then((response) => {
            // console.log("0000000000",response.data)
            if (response.data.errorCode == 505) {
              //no adult tkt

              paymentResponce(505);
            } else if (response.data.errorCode == 504) {
              // no child tkt
              paymentResponce(504);
            } else if (response.data.errorCode == 100) {
              paymentResponce(100);
            } else if (response.data.errorCode == 51) {
              // In Sufficient balance
              paymentResponce(51);
            } else if (response.data.errorCode == 5) {
              // Do not Honor
              paymentResponce(5);
            } else if (response.data.errorCode == 91) {
              // Issuer Switch inoperative
              paymentResponce(91);
            } else if (response.data.errorCode == 6824) {
              //transaction cancel by user
              paymentResponce(6824);
            } else if (response.data.errorCode == 0) {
              paymentResponce(0);

              const fileURL =
                FILE_STORE_BASE_URL +
                response.data.bookingTickPdfPath;
              const filename = "e-Ticket.pdf";
              download_file(fileURL, filename);
            } else {
              paymentResponce(99999);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const submitdata = {
          bookPaymentRefId: urlObject.paymentTransactionId,
          bookB2bId: bookingObject.bookB2bId,
          bookB2bUserId: bookingObject.bookB2bUserId,
          bookB2cId: 0,
          attractionsId: bookingObject.attractionsId,
          bookCustomerName: bookingObject.bookCustomerName,
          bookCustomerEmail: bookingObject.bookCustomerEmail,
          bookMobileNumber: bookingObject.bookMobileNumber,
          ticketTypeId: bookingObject.ticketTypeId,
          bookNofAdult: bookingObject.bookNofAdult,
          bookNofChild: bookingObject.bookNofChild,
          bookAdultPrice: bookingObject.bookAdultPrice,
          bookChildPrice: bookingObject.bookChildPrice,
          bookTravelDate: bookingObject.bookTravellDate,
          bookPaymentMode: bookingObject.bookPaymentMode,
          bookTotal: bookingObject.bookTotal,
          bookingAddon: bookingObject.bookingAddon,
        };

        // console.log("Other Ticket", submitdata);

        // if(){

        // }else{

        // }
        setshowAlert(1);
        axios
          .post(requests.getPaymentConfirmation, submitdata)
          .then((response) => {
            setfinalRes(response.data);
            // console.log("33333333333333");

            if (response.data.errorCode == 505) {
              //no adult tkt

              paymentResponce(505);
            } else if (response.data.errorCode == 504) {
              // no child tkt
              paymentResponce(504);
            } else if (response.data.errorCode == 100) {
              paymentResponce(100);
            } else if (response.data.errorCode == 51) {
              // In Sufficient balance
              paymentResponce(51);
            } else if (response.data.errorCode == 5) {
              // Do not Honor
              paymentResponce(5);
            } else if (response.data.errorCode == 91) {
              // Issuer Switch inoperative
              paymentResponce(91);
            } else if (response.data.errorCode == 6824) {
              //transaction cancel by user
              paymentResponce(6824);
            } else if (response.data.errorCode == 0) {
              paymentResponce(0);

              const fileURL =
                FILE_STORE_BASE_URL +
                response.data.bookingTickPdfPath;
              const filename = "e-Ticket.pdf";
              download_file(fileURL, filename);
            } else {
              paymentResponce(99999);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, 2000);
    // console.log("4444444444444444");
  }, []);

  const [resMessage, setresMessage] = useState("");
  const [resCode, setresCode] = useState(0);
  const [alertColor, setalertColor] = useState("");
  const [thankMsg, setthankMsg] = useState("");
  const paymentResponce = (payval) => {
    if (payval == 505) {
      // Ticket not available
      setresMessage("Ticket Not Avilable");
      setalertColor("info");
      setresCode(1);
    } else if (payval == 504) {
      // Adult ticket not avilable
      setresMessage("Child Not Avilable");
      setalertColor("info");
      setresCode(1);
    } else if (payval == 100) {
      // .......
    } else if (payval == 51) {
      // In Sufficient balance
      setresMessage("In Sufficient Balance");
      setalertColor("info");
      setresCode(1);
    } else if (payval == 5) {
      // Do not Honor
      setresMessage("Do Not Honor");
      setalertColor("danger");
      setresCode(1);
    } else if (payval == 91) {
      // Issuer Switch inoperative
      setresMessage("Issuer Switch Inoperative");
      setalertColor("info");
      setresCode(1);
    } else if (payval == 6824) {
      setresMessage("Payment Cancel By User");
      setalertColor("info");
      setresCode(1);
    } else if (payval == 0) {
      setresMessage(
        "Your Booking Has Been Confirmed Yor e-Ticket downloaded Automatically"
      );
      setalertColor("success");
      setresCode(1);
      setthankMsg(1);
      setbookingState("Your booking has been confirmed");
    } else if (payval == 99999) {
      setresMessage("Some Technical Problem please Contact Our Executive");
      setalertColor("info");
      setresCode(1);
    } else {
      setresMessage("Some Technical Problem please Contact Our Executive");
      setalertColor("info");
      setresCode(1);
    }
  };

  // Swal.fire({
  //   title: "Booking Confirmed",
  //   text: "Thank You For Your Booking, press OK to download your e-Ticket",
  //   showDenyButton: false,
  //   showCancelButton: false,
  //   confirmButtonText: "Ok",
  //   denyButtonText: `Don't save`,
  // }).then((result) => {
  //   /* Read more about isConfirmed, isDenied below */
  //   if (result.isConfirmed) {
  //     download_file(fileURL, filename);
  //   }
  // });

  // const tktdownload =(fileURL, filename)=>{

  // }

  /* Helper function */
  function download_file(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }
  //bookCustomerName  bookCustomerEmail
  return (
    <>
      <TopNav />
      <CommonBanner title={bookingState} imgUrl="thankyou.jpg" />

      <div className="about-section pd-top-80">
        {resCode == 1 ? <Alert color={alertColor}>{resMessage}</Alert> : null}
        {thankMsg == 1 ? (
          <div className="container">
            <h3 className="title">
              Thank you for your booking , {finalRes.bookCustomerName}
            </h3>

            <p>
              A Confirmation email will be send you at{" "}
              {finalRes.bookCustomerEmail}, with your complete booking details
              and e-Ticket
            </p>
          </div>
        ) : null}
      </div>

      <Subscribe />
      <Footer />
    </>
  );
};

export default Paymentconfirmation;
