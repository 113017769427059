import { Row, Col } from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import { IMAGES_PATH_URL } from "../../utils/constants";
import VideoPlayer  from "./VideoPlayer"
import DemoV4 from "./DemoV4";
const BannerImage = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagelinkfromserver =
  IMAGES_PATH_URL;
  let imagealt = "image";

  return (
    <>
      {/* <div
        className="main-banner-area jarallax"
        style={{
          backgroundImage:
            "url(" + publicUrl + "assets/img/banner/homebanner.jpg)",
        }}
      >
        <div className="content">
          <div className="container">
            <h2>Explore, Discover, Travel</h2>
            <h1>ADVENTURE</h1>
            <h1 className="shadow">ADVENTURE</h1>
            <div className="scroll-down">
              <a href="#main_search" className="text-center">
                <span />
                <i className="la la-long-arrow-down" />
              </a>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container1">
        {/* <Row>
          <Col sm={8} ClassName="padding-0">
            <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "home0011.png",

                  height: "430px",
                }}
              ></div>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "home0012.png",

                  height: "430px",
                }}
              ></div>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "home0013.png",

                  height: "430px",
                }}
              ></div>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "home0014.png",

                  height: "430px",
                }}
              ></div>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "home0015.png",

                  height: "430px",
                }}
              ></div>
            </Carousel>
          </Col>
          <Col sm={4} ClassName="padding-0">
            <Col>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "home0031.jpeg)",

                  height: "200px",
                }}
              ></div>
            </Col>
            <br />
            <Col>
              <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
                <div
                  className="main-banner-area jarallax"
                  style={{
                    backgroundImage:
                      "url(" + imagelinkfromserver + "home0021.jpg)",

                    height: "200px",
                  }}
                ></div>
                <div
                  className="main-banner-area jarallax"
                  style={{
                    backgroundImage:
                      "url(" + imagelinkfromserver + "home0022.png)",

                    height: "200px",
                  }}
                ></div>
                <div
                  className="main-banner-area jarallax"
                  style={{
                    backgroundImage:
                      "url(" + imagelinkfromserver + "home0023.png)",

                    height: "200px",
                  }}
                ></div>
              </Carousel>
            </Col>
          </Col>
        </Row> */}

        {/* <div className="row"> */}
          <div>
          <DemoV4/> 
            {/* <VideoPlayer/> */}
          {/* <div
                className="main-banner-area jarallax"
                style={{ backgroundImage: "url(" + publicUrl + "assets/img/bg/tourist_attractions_in_Dubai.jpg)", height: "500px", }}
              > */}
                {/* <DemoV4/> */}
                {/* <VideoPlayer/> */}
              {/* </div> */}
            {/* <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "homeMain01.jpg",

                  height: "530px",
                }}
              ></div>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "homeMain02.jpg",

                  height: "530px",
                }}
              ></div>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "homeMain03.jpg",

                  height: "530px",
                }}
              ></div>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "homeMain04.jpg",

                  height: "530px",
                }}
              ></div>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "homeMain05.jpg",

                  height: "530px",
                }}
              ></div>
            </Carousel> */}
          </div>
          {/* <div className="col-lg-4 padding-1">
            <Col>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "home0031.jpeg)",

                  height: "250px",
                }}
              ></div>
            </Col>
            <br />
            <Col>
              <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
                <div
                  className="main-banner-area jarallax"
                  style={{
                    backgroundImage:
                      "url(" + imagelinkfromserver + "home0011.png)",

                    height: "250px",
                  }}
                ></div>
                <div
                  className="main-banner-area jarallax"
                  style={{
                    backgroundImage:
                      "url(" + imagelinkfromserver + "home0012.png)",

                    height: "250px",
                  }}
                ></div>
                <div
                  className="main-banner-area jarallax"
                  style={{
                    backgroundImage:
                      "url(" + imagelinkfromserver + "home0013.png)",

                    height: "250px",
                  }}
                ></div>
                 <div
                  className="main-banner-area jarallax"
                  style={{
                    backgroundImage:
                      "url(" + imagelinkfromserver + "home0014.png)",

                    height: "250px",
                  }}
                ></div>
                 <div
                  className="main-banner-area jarallax"
                  style={{
                    backgroundImage:
                      "url(" + imagelinkfromserver + "home0015.png)",

                    height: "250px",
                  }}
                ></div>
              </Carousel>
            </Col>
          </div> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default BannerImage;
