import CommonBanner from "../global-components/common_banner";
import Footer from "../global-components/footer";
import Subscribe from "../global-components/subscribe";
import TopNav from "../global-components/top_nav";
import UserProfileComp from "./UserProfileComp";

const Userprofile = () => {
  return (
    <>
      <TopNav />
      <CommonBanner title="Profile Page" imgUrl ="attBanner.jpeg" />
      <UserProfileComp />
      <Subscribe />
      <Footer />
    </>
  );
};

export default Userprofile;
