const VisaData = [
  {
    id: 1,
    visaType: "30 - DAYS TOURIST SINGLE ENTRY",
    insurance: "WITH INSURANCE",
    amount: "280.00",
    imgurl: "001",
  },
  {
    id: 2,
    visaType: " 90 - DAYS TOURIST SINGLE ENTRY",
    insurance: " WITH INSURANCE",
    amount: "680.00",
    imgurl: "002",
  },
  {
    id: 3,
    visaType: "30 - DAYS TOURIST MULTI ENTRY",
    insurance: "WITHOUT INSURANCE",
    amount: "700.00",
    imgurl: "003",
  },
  {
    id: 4,
    visaType: "90 - DAYS TOURIST MULTI ENTRY",
    insurance: "WITHOUT INSURANCE",
    amount: "1,750.00",
    imgurl: "004",
  },
  {
    id: 5,
    visaType: "30 - DAYS INSIDE COUNTRY",
    insurance: "WITH INSURANCE",
    amount: "1,560.00",
    imgurl: "005",
  },
  {
    id: 6,
    visaType: "90 - DAYS INSIDE COUNTRY",
    insurance: " WITH INSURANCE",
    amount: "2,025.00",
    imgurl: "006",
  },
  {
    id: 7,
    visaType: "30 - DAYS EXTENSION",
    insurance: "SAME COMPANY VISA",
    amount: "850.00",
    imgurl: "007",
  },

  {
    id: 8,
    visaType: "48 -HRS TRANSIT VISA",
    insurance: "WITH INSURANCE",

    amount: "90.00",
    imgurl: "008",
  },

  {
    id: 9,
    visaType: "96-HRS TRANSIT VISA",
    insurance: "WITH INSURANCE",

    amount: "120.00",
    imgurl: "009",
  },
];
export default VisaData;
