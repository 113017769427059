import React, { useState } from 'react'
import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } 
from 'react-phone-number-input'
export const PhoneInputField = (props) => {
  console.log('props::::::', props)
  const [value, setValue] = useState()
  return (
    <>
    <PhoneInput
    //   style={...}
      defaultCountry="US"
      value={props.value}
      // onChange={setValue} 
      onChange={param => props.onChange(param)}
      error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
      />
      {/* Is valid: {value && isPossiblePhoneNumber(value) ? 'true' : 'false'}<br/> */}
{/* Is valid: {value && isValidPhoneNumber(value) ? 'true' : 'false'} */}
{/* National: {value && formatPhoneNumber(value)}<br/> */}
{/* International: {value && formatPhoneNumberIntl(value)}<br/> */}
      </>
  )
}