const RouteMenu = [
    {
        name: "Home",
        fixMenu: true,
        path: "/",
        SubMemu: []

    },
    {
        name: "Attraction",
        fixMenu: true,
        path: "/attraction",
        SubMemu: []

    },
    {
        name: "Combo",
        fixMenu: true,
        path: "/combo",
        SubMemu: []

    },
    {
        name: "Blog",
        fixMenu: true,
        path: "/blog",
        SubMemu: []

    },
    {
        name: "About Us",
        fixMenu: true,
        path: "/about-us",
        SubMemu: []

    },

    // {
    //     name: "Tours",
    //      fixMenu: true,
    //     path: "/tour-packages",
    //     // SubMemu: [
    //     //     {
    //     //         name: "Holiday",
    //     //         path: "/pakages/holiday"
    //     //     },
    //     //     {
    //     //         name: "Honey Moon",
    //     //         path: "/pakages/honeymoon"
    //     //     },
    //     // ]

    // },
    // {
    //     name: "Visa" ,
    //     fixMenu: true,
    //     path: "/visa-services"
    
    // },
// {
//     name: "EXPO 2020" ,
//     fixMenu: true,
//     path: "/dubai-expo-2020"

// },

    {
        name: "Contact",
        fixMenu: true,
        path: "/contact-page"
    },
    // {
    //     name: "TestPayment",
    //     fixMenu: true,
    //     path: "/Test-payment"
    // },

]
export default RouteMenu;