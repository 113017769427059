import { Form, reduxForm, Field } from "redux-form";
import { CardTitle, Alert } from "reactstrap";
import RenderField from "../form-components/RenderField";
import axios from "axios";
import requests from "../utils/Requests";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import encryptStorage from "../utils/Encryptstorage";

const validate = (values) => {
  const errors = {};
  if (!values.userName) {
    errors.userName = "Enter Email ID";
  }
  if (!values.password) {
    errors.password = "Enter Password";
  }

  return errors;
};

const LoginForm = (props) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  let history = useHistory();
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [servserRes, setservserRes] = useState();
  const [alertTime, setalertTime] = useState(0);
  useEffect(() => {}, []);
  const handleSubmitlogin = (values, dispatch) => {
    axios
      .post(requests.getuserlogindetails, values)
      .then((res) => {
        console.log(res.data);

        let errcode = res.data.errorCode;

        if (errcode == 101) {
          sessionStorage.setItem("eMail", res.data.userName);
          props.otppage(101);

          props.email(res.data.userName);
          props.otppage(101);
        } else if (errcode == 420) {
          setservserRes("Invalid User Name Or Password ");
          setalertTime(1);

          setTimeout(function () {
            //Put All Your Code Here, Which You Want To Execute After Some Delay Time.
            // Alert.alert("Alert Shows After 5 Seconds of Delay.")

            setalertTime(0);
          }, 5000);
        } else if (errcode == 0) {
          //user logion B2C
          encryptStorage.setItem("enl", res.data);
          sessionStorage.setItem("isLoggedIn", "yes");

          window.location.href = "/";
        } else if (errcode == 301) {
          //301 for agency login
          encryptStorage.setItem("enl", res.data);
          sessionStorage.setItem("isLoggedIn", "yes");

          history.goBack();
        } else if (errcode == 302) {
          //302 for agend user
          encryptStorage.setItem("enl", res.data);
          sessionStorage.setItem("isLoggedIn", "yes");

          history.goBack();
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {alertTime == 1 ? (
        <>
          <Alert color="danger">{servserRes}</Alert>
        </>
      ) : null}
      <Form onSubmit={handleSubmit(handleSubmitlogin.bind(this))}>
        <Field
          name="userName"
          type="text"
          icon=""
          label="Email ID "
          component={RenderField.RenderTextField}
        />
        <Field
          name="password"
          type="password"
          label="Password"
          component={RenderField.RenderTextField}
        />
        <br />
        <button type="submit" class="btn btn-yellow" disabled={submitting}>
          Login{" "}
        </button>
      </Form>
    </>
  );
};

export default reduxForm({
  form: "LoginForm",
  validate,
})(LoginForm);
