import { createStore, combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";

import { myreducer } from "./Resreducer";
import { cartReducers } from "../../redux/reducers/cartReducers";

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form"
  myreducer: myreducer,
  cartReducers: cartReducers
});

const store = createStore(reducer);

//+  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

export default store;
