import { BASE_URL, IMAGES_PATH_URL } from "./constants";
const baseURL = BASE_URL + "/tourapp/"; // production
// const baseURL = BASE_URL; // local
const imagebaseUrl = IMAGES_PATH_URL; // server for image BASE_URL  Test Server http://199.192.31.213:8080/tourapp/
const requests = {
  getPaymentConfirmationBurjKhalifa: baseURL+ "v1.0/getPaymentConfirmationBurjKhalifa",
  setUserCartDetails: baseURL+ "v1.0/setUserCartDetails",
  removeUserCart: baseURL+ "v1.0/removeUserCart",
  getUserCartListAll: baseURL + "v1.0/getUserCartListAll",
  confirmBurjTicket: baseURL + "v1.0/confirmBurjTicket",
  saveBurjTicket: baseURL + "v1.0/saveBurjTicket",
  getBurjTicketTypeWithRates: baseURL + "v1.0/getBurjTicketTypeWithRates",
  getBurjTimeSlotWithRates: baseURL + "v1.0/getBurjTimeSlotWithRates",
  getBurjTimeSlot: baseURL + "v1.0/getBurjTimeSlot",
  getAgencyPaymentUrl: baseURL + "v1.0/getAgencyPaymentUrl",
  getSalesReportForB2b: baseURL + "v1.0/getSalesReportForB2b",
  getPaymentConfirmation: baseURL + "v1.0/getPaymentConfirmation",
  getPaymentGatewayUrl: baseURL + "v1.0/getPaymentGatewayUrl",
  paymentRegistration: baseURL + "v1.0/paymentRegistration",
  getAttractionTicketTypePrice: baseURL + "v1.0/getAttractionTicketTypePrice",
  getTicketTypeListByAttraction: baseURL + "v1.0/gettickettypelistbyattraction",
  getComboTicketListByComboId: baseURL + "v1.0/getComboTicketListByComboId",
  getTicketGroupListForAttraction:
    baseURL + "v1.0/getTicketGroupListForAttraction",
  getattractionallbygroup: baseURL + "v1.0/getattractionallbygroup",
  getagencydetails: baseURL + "v1.0/getagencydetails",
  getagencylist: baseURL + "v1.0/getagencylist",
  getAttractionAll: baseURL + "v1.0/getattractionall",
  getComboall: baseURL + "v1.0/getComboall",
  getImgBaseUrl: imagebaseUrl,
  getAttractionDetails: baseURL + "v1.0/getattractiondetails",
  getComboDetails: baseURL + "v1.0/getComboDetails",
  getTourPackageList: baseURL + "v1.0/gettourpackageall",
  getTourPackageSingle: baseURL + "v1.0/gettourpackageDetails",
  getallparkgroup: baseURL + "v1.0/getattractiongrouplist",
  setaddonformlist: baseURL + "v1.0/setaddonformlist",
  getaddonbannerlist: baseURL + "v1.0/getaddonbannerlist",
  getuserlogindetails: baseURL + "v1.0/getloginuserdetails",
  setotpforexistinguser: baseURL + "v1.0/setotpforexistinguser",
  updatePasswordForUser: baseURL + "v1.0/updatePasswordForUser",
  postBooking: baseURL + "v1.0/setbooking",
  setloginuserdetails: baseURL + "v1.0/setloginuserdetails",
  verifyotpuser: baseURL + "v1.0/verifyotpuser",
  getVisaList: baseURL + "v1.0/getVisaList",
  setenquiry: baseURL + "v1.0/setenquiry",
  setenquiryBooking: baseURL + "v1.0/setEnquiryBooking",
  getFileUpload: baseURL + "v1.0/fileupload",
  fileUploadProfile: baseURL + "v1.0/fileUploadProfile",
  paymentValidate: baseURL + "v1.0/paymentValidate",
  updateAgencyDetails: baseURL + "v1.0/updateAgencyDetails",
  setcustomer: baseURL + "v1.0/setcustomer",
  setagencyuser: baseURL + "v1.0/setagencyuser",
  removeBooking: baseURL + "v1.0/removeBooking",
  getBlogAll: baseURL + "v1.0/getBlogAll",
  getBlogdetails: baseURL + "v1.0/getBlogdetails",
  imgpath: imagebaseUrl,
};
export default requests;
