import { Row, Col } from "reactstrap";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import encryptStorage from "../../utils/Encryptstorage";
const PaymentPageNew = (props) => {
  let attname;
  const [bookdetails, setbookdetails] = useState([]);
  const [paymentPortel, setpaymentPortel] = useState("");

  const [paymentTransactionId, setpaymentTransactionId] = useState("");
  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    const url = encryptStorage.getItem("url"); //obj
    let urlObject = JSON.parse(url);

    setpaymentTransactionId(urlObject.paymentTransactionId);
    setpaymentPortel(urlObject.paymentUrl);

    const obj = encryptStorage.getItem("DFuck");
    let bookingObject = JSON.parse(obj);
    setbookdetails(bookingObject);

    // console.log("Url Object", urlObject);
    // console.log("paymentPortel", paymentPortel);
    // console.log("paymentTransactionId", paymentTransactionId);

    setTimeout(function () {
      setisloading(false);
    }, 1000);

    // console.log("sdsfsfsfsfsfs", urlObject);
    // console.log("sdsfsfsdfdfdfd", bookingObject);
  }, []);

  attname = sessionStorage.getItem("attname");

  //paymentUrl, paymentTransactionId

  let history = useHistory();

  return (
    <>
      <Row>
        <Col xs={2}></Col>
        <Col xs={8}>
          <div className="container rounded bg-white">
            <div className="d-flex justify-content-end">
              <a class="cancel com-color" onClick={history.goBack}>
                Cancel and return to website
              </a>
            </div>
            <div className="d-flex justify-content-center">
              <h4 className="single-page-small-title">Your Booking Details</h4>
            </div>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Park Name </h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? <p>Please Wait</p> : attname}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Booking Customer Name</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookCustomerName
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Your Email</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookCustomerEmail
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Contact</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookMobileNumber
                  )}
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Ticket Type</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">{ctkttype}</div>
              </Col>
            </Row> */}
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Number Of Adult</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookNofAdult
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Number Of Child</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {bookdetails.bookNofChild == null ? (
                    0
                  ) : isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookNofChild
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Date</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookTravellDate
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Total Amount<small>(include service tax)</small></h6>
                </div>
              </Col>

              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookTotal
                  )}
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={6}></Col>

              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <form action={paymentPortel} method="post">
                    <input
                      type="Hidden"
                      name="TransactionID"
                      value={paymentTransactionId}
                    />

                    <div class="buttons">
                      {" "}
                      {isloading == true ? null : (
                        <button class="btn btn-success btn-block" type="submit">
                          Proceed to payment
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={2}></Col>
      </Row>

      {/* <button onClick={()=>testPaymentform()}>Test Payment</button> */}
    </>
  );
};

export default PaymentPageNew;
