import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import requests from "../../utils/Requests";
import encryptStorage from "../../utils/Encryptstorage";
import PinDropIcon from '@mui/icons-material/PinDrop';
import StarRating from "../../common/StarRating";
import {
  Button
} from "reactstrap";
import {Row,Col} from 'reactstrap'
import MainListCombo from "../../commonComponents/MainListCombo";
const ComboTourorderlist = () => {
  let imagealt = "image";
  // const [isLoading, setLoading] = useState(true);
  const [LoggedIn, setLoggedIn] = useState();
  const [packprice, setpackprice] = useState();
  const [attractionsId, setAttractionsId] = useState();
  let agencyuser = 0;
  let agency = 0;
  useEffect(() => {
    // let b2b = sessionStorage.getItem("b2b");
    // let b2bUser = sessionStorage.getItem("b2bUser");
    let login = sessionStorage.getItem("isLoggedIn");
    if (login == "yes") {
      const enl = encryptStorage.getItem("enl"); //obj //agency.agencyId
      let urlObject = JSON.parse(enl);
      if (urlObject.b2b == true) {
        let agency = urlObject.agency.agencyId;
        // setagency(agency);
        getAllTourList(agency, 0);
        setLoggedIn(1);
      } else if (urlObject.b2bUser == true) {
        let agencyuser = urlObject.agencyUser.agencyUserId;
        // setagencyuser(agencyuser);
        getAllTourList(0, agencyuser);
        setLoggedIn(1);
      } else {
        // setb2cUser(1);
        getAllTourList(0, 0);
        setLoggedIn(0);
      }
    } else {
      getAllTourList(0, 0);
      setLoggedIn(0);
    }
    // getAllTourList();
    getallparkgroup();
  }, []);

  const [tourlist, setTourlist] = useState([].slice(0, 1));
  const [allTours, setallTours] = useState([]);

  let attraction = { attractionId: 1 };
  const getAllTourList = async (b2bid, b2buserid) => {
    await axios
      .post(requests.getComboall, {
        attractionId: 1,
        agencyId: b2bid,
        agencyUserId: b2buserid,
      })
      .then((res) => {
        // console.log(
        //   "Response", res.data
        // )
        setallTours(res.data);
        setTourlist(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [parkGroup, setparkGroup] = useState([
    { value: "all-tours", name: "All Tour" },
  ]);

  const getallparkgroup = async () => {
    await axios
      .post(requests.getallparkgroup, attraction)
      .then((res) => {
        const values = [...parkGroup];
        parkGroup.length = 0;
        for (let i = 0; i < res.data.length; i++) {
          values.push({
            name: res.data[i].grpName,
            value: res.data[i].attractionGroupId,
          });
        }
        setparkGroup(values);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterItems = (FilterList) => {
    //   console.log(FilterList);
    //  setTourlist(allTours);

    if (FilterList === "all-tours") {
      const toursfilter = allTours.filter(
        (tour) => tour.attGroup !== FilterList
      );

      setTourlist(toursfilter);
    } else {
      const toursfilter = allTours.filter(
        (tour) => tour.attGroup === FilterList
      );

      setTourlist(toursfilter);
    }
    window.scrollTo(0, 0);
    //const newItem = tourlist.filter((tour) => tour.attGroup === FilterList);
    //setTourlist( toursfilter.filter((tour) => tour.attGroup === FilterList));
  };
  // if (isLoading) {
  //   <p>loading...</p>;
  // }
  const [search, setsearch] = useState("");
  const fulterAttraction = tourlist.filter((attract) => {
    return attract.attName.toLowerCase().includes(search.toLocaleLowerCase());
  });
  const selectAttraction = attractionsId => {
    setAttractionsId(attractionsId)
  }
  return (
    <>
      <div className="tour-list-area pd-top-120 viaje-go-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-8 order-lg-12">
              {/* Attraction */}

              {fulterAttraction.map((alltour, index) => (
                <>
                  <div key={index}>
                    <div className="tour-list-area">
                      <div className="single-destinations-list style-three">
                        <div className="thumb">
                          <Link
                            to={`combo-tour-pack-details/${alltour.attractionsId}`}
                          >
                            <img
                              // src={publicUrl + "assets/img/destination-list/12.png"}
                              src={requests.imgpath + alltour.attThumbnailImage}
                              alt="list"
                            />
                          </Link>
                        </div>

                        <div className="details">
                          <h4 className="title">
                            {/* <Link to="/tour-details"></Link> */}
                            <Link
                              to={`combo-tour-pack-details/${alltour.attractionsId}`}
                            >
                              {alltour.attName}
                            </Link>
                          </h4>

                          {alltour.attDescription ? (
                            <p className="content">
                              {" "}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: alltour.attDescription.substring(
                                    0,
                                    250
                                  ),
                                }}
                              />
                            </p>
                          ) : null}

<hr style={{marginLeft: '-2.5%', width: '105%', marginTop: '5%', marginBottom: '0%', paddingBottom: '0%'}}/>
                          <div className="list-price-meta" style={{ marginBottom: '0px'}}>
                            {/* <ul className="tp-list-meta d-inline-block">
                       
                                <i className="fa fa-star" /> 4.3
                              </li>
                            </ul> */}
                            <div className="tp-price-meta d-inline-block">
                              {/* <p>Location</p> */}
                              <h5 style={{marginLeft: '-30%'}}><PinDropIcon />  <span style={{marginTop: '-2px', color: 'black', fontSize:'15px'}}>{alltour.attCity}</span></h5>
                            </div>
                            <div style={{marginLeft: '-4%'}} className="tp-price-meta d-inline-block">
                      <StarRating/>
                      </div> 
                            <div className="tp-price-meta d-inline-block" style={{marginLeft: '2%'}}>
                              {/* <p>Price</p>
                              <h5>
                                {LoggedIn == 1
                                  ? alltour.b2bAdultPrice
                                  : alltour.b2cAdultPrice}
                                &nbsp; &nbsp; &nbsp;
                                <span>AED</span>
                              </h5> */}
                           
                        <p><div className="priceColorWhite">Adult Price From</div></p>
                        <h2>{alltour.adultPrice}  &nbsp;&nbsp;&nbsp;
                        <small id='attractionAed'>AED</small></h2>
                        </div>
                        <div className="tp-price-meta d-inline-block">
                      <p><div className="priceColorWhite">Child Price From</div></p>
                      <h2>{alltour.childPrice}  &nbsp;&nbsp;&nbsp;
                        <small id='attractionAed'>AED</small></h2>
                      
                            </div>
                            <div className="tp-price-meta d-inline-block">
                            <Button
                                  color="primary"
                                  type="submit"
                                  className="btn btn-yellow"
                                  onClick={()=> selectAttraction(alltour.attractionsId)}
                                >
                                  SELECT
                                </Button>
                                </div>
                                {/* {alltour.attractionsId === attractionsId && 
                                (<MainList attractionsId={alltour.attractionsId}/>)} */}
                            {/* <div className="tp-price-meta d-inline-block">
                              <Link
                                className="btn btn-yellow float-end"
                                to={`tour-pack-details/${alltour.attractionsId}`}
                              >
                                Book
                                {/* <i class="fa fa-paper-plane"></i> */}
                              {/* </Link> */}
                            {/* </div>  */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {alltour.attractionsId === attractionsId && (
                  <div>
                  <MainListCombo comboId={alltour.attractionsId} attractions={alltour} width={'150vh'}/>
                  </div>)}
                </>
              ))}
            </div>

            <div className="col-xl-3 col-lg-4 order-lg-1">
              <div className="sidebar-area">
                <ul class="nav nav-tabs tp-tabs style-two">
                  <div className="widget-tour-list-search">
                    <div className="search-form">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={(e) => setsearch(e.target.value)}
                        />
                      </div>
                      <button className="submit-btn" type="submit">
                        <i className="ti-search" />
                      </button>
                    </div>
                  </div>

                  {/* Menu */}
                  {/* <AttractionMenu attMenu={parkGroup} filterItems={filterItems}/> */}
                  {/* onClick={()=>filterItems(group.value)} */}

                  {parkGroup.map((group, index) => (
                    <li class="nav-item" key={index}>
                      <a
                        class="nav-link "
                        data-toggle="tab"
                        href=""
                        value={group.value}
                        onClick={() => filterItems(group.value)}
                      >
                        {group.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComboTourorderlist;
