import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Field, Form, reduxForm } from "redux-form";
import { useState, useEffect } from "react";
import RenderField from "../../form-components/RenderField";
import { Label, Row, Col, Button, Alert, Spinner } from "reactstrap";
import axios from "axios";
import requests from "../../utils/Requests";
import { FILE_STORE_BASE_URL, IMAGES_PATH_URL } from '../../utils/constants'
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const Bookinglist = (props) => {
  useEffect(() => {
    //idForReport, loginUser
  }, []);
  const [isLoading, setisLoading] = useState(0);
  const { handleSubmit, pristine, reset, submitting } = props;
  const getBookingDetails = (values) => {
    //getSalesReportForB2b
    setisLoading(1);
    // let agencyid = 0,
    //   agencyUserid = 0;
    // if (props.loginUser == 1) {
    //   agencyid = props.idForReport;
    // } else {
    //   agencyUserid = props.idForReport;
    // }

    const submitData = {
      startDate: values.startDate,
      endDate: values.endDate,
      agencyId: props.loginUser !== 3 ? props.idForReport : 0,
      customerId: props.loginUser === 3 ? props.idForReport : 0,
      // agencyUserId: agencyUserid,
    };

    console.log(submitData);

    axios
      .post(requests.getSalesReportForB2b, submitData)
      .then((response) => {
        setRecordForTable(response.data);
        setisLoading(0);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setRecordForTable = (record) => {
    const tempRecord = record.map(
      ({
        bookingId,
        ticketNumber,
        bookingRefNumber,
        transactionId,
        attractionName,
        paxName,
        contactNumber,
        totalSalesAmount,
        bookingDate,
        bookPaymentMode,
        invoiceNumber,
        pdfFileName,
      }) => ({
        bookingId: bookingId,
        ticketNumber: ticketNumber,
        bookingRefNumber: bookingRefNumber,
        transactionId: transactionId,
        attractionName: attractionName,
        paxName: paxName,
        contactNumber,
        contactNumber: contactNumber,
        totalSalesAmount: totalSalesAmount,
        bookingDate: bookingDate,
        invoiceNumber: invoiceNumber,
        bookPaymentMode: paymode(bookPaymentMode),
        pdfFileName: pdfFileName,
      })
    );
    setrowData(tempRecord);
  };

  const paymode = (bookPaymentMode) => {
    if (bookPaymentMode == 1) {
      return "Online";
    } else {
      return "Credit";
    }
  };
  const [rowData, setrowData] = useState([]);
  const columnDefs = [
    { headerName: "Ticket Number", field: "ticketNumber" },
    { headerName: "Booking RefNo", field: "bookingRefNumber" },
    { headerName: "Invoivce ", field: "invoiceNumber" },
    { headerName: "Transaction", field: "transactionId" },
    { headerName: "Attraction Name", field: "attractionName" },
    { headerName: "Pax Name", field: "paxName" },
    { headerName: "Number", field: "contactNumber" },
    { headerName: "Sales Amount", field: "totalSalesAmount" },
    { headerName: "Sales Date", field: "bookingDate" },
    { headerName: "PaymentMode", field: "bookPaymentMode" },
    {
      headerName: "Actions",
      filter: false,
      field: "ticketNumber",
      cellRendererFramework: (params) => (
        <div>
          <Button color="primary" onClick={() => actionButton(params)}>
            <i class="fa fa-download" aria-hidden="true"></i>
          </Button>
        </div>
      ),
    },
  ];
  const actionButton = (params) => {
    console.log(params);
    const pdfName = params.data.bookingRefNumber+'-'+params.data.invoiceNumber+'.pdf'
    const fileURL =
    IMAGES_PATH_URL + pdfName;
    const filename = "e-Ticket.pdf";
    download_file(fileURL, filename);
    //  alert(`${params.data.tourPackageId} `);
    //{`tour-pack-details/${alltour.attractionsId}`}"/attraction/attraction-creation"
  };

  /* Helper function */

  function download_file(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }

  const defaultColDef = {
    sortable: true,
    editable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <>
      <Form onSubmit={handleSubmit(getBookingDetails.bind(this))}>
        <Label>Select Date Range</Label>
        <Row>
          <Col sm={3}>
            <Field
              name="startDate"
              type="date"
              component={RenderField.RenderTextField}
            />
          </Col>
          <Col sm={3}>
            <Field
              name="endDate"
              type="date"
              component={RenderField.RenderTextField}
            />
          </Col>
        </Row>
        <br />
        <div className="float-center">
          {/* <Button color="primary" disabled={submitting}>
            Submit
          </Button>{" "}
          &nbsp; &nbsp;&nbsp;
        </div>
      </Form>
      <br /> */}
      <Button color="primary" disabled={submitting}>
            Submit
          </Button>{" "}
          &nbsp; &nbsp;&nbsp;
          <Button
            color="danger"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear
          </Button>{" "}
        </div>
      </Form>
      <br />

      {isLoading == 1 ? (
        <>
          {" "}
          <Alert color="primary">
            Your Data Is Loading Please Wait <Spinner color="light" />
          </Alert>
        </>
      ) : null}
      {isLoading == 0 ? (
        <Button
          color="primary"
          onClick={(e) => exportToCSV(rowData, "Daily_Sales_Report")}
        >
          Download CSV
        </Button>
      ) : null}
      <br />
      <div className="">
        <div className="ag-theme-alpine" style={{ height: "500px" }}>
          <AgGridReact
            id="Inventory"
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={defaultColDef}
            // onGridReady={onGridReady}
            pagination={true}
            // paginationPageSize={5}
            // paginationAutoPageSize={true}
          ></AgGridReact>
        </div>
      </div>
    </>
  );
};
export default reduxForm({
  form: "Bookinglist",
})(Bookinglist);
