import CommonBanner from "./global-components/common_banner";
import Footer from "./global-components/footer";
import Subscribe from "./global-components/subscribe";
import TopNav from "./global-components/top_nav";

const Termsandconditions = () => {
  return (
    <>
      <TopNav />
      <CommonBanner title="Terms And Contions - Hunjra Tourism" />
      <div className="container">
        <div className="tour-details-wrap">
          <h4 className="single-page-small-title">Terms And Contions</h4>
          <p>
            Thank you for choosing our travel package(s). By booking a trip
            through our website, you´re deemed to have agreed to its terms of
            use. Please read the following terms and procedures in order to make
            sure that you´ve clearly understood the conditions of your preferred
            trip. All of the below mentioned terms and conditions are applicable
            for bookings made through our websites such as:
          </p>
          <ul>
            <li>
              <h4 className="single-page-small-title">Pricing </h4>
            </li>
            <p>
              Our policy assures you of an economic pricing, along with the
              convenience of making reservation online . Unless otherwise
              mentioned, prices quoted on our website are charged on per person
              basis, and do not include tips given to tour guides or drivers,
              passport / visa fee, travel insurance, drinks or food,
              accommodation, room services, and laundry. The published rates may
              change without prior notice, particularly in case of any
              unforeseen circumstance, such as increase in airline tickets,
              hotel rates, or transport expenses.{" "}
            </p>

            <br />
            <li>
              <h4 className="single-page-small-title">Methods of Payment </h4>
              <p>
                We accept almost all major credit cards, such as Visa,
                MasterCard, American Express, Discover, and Diners . Full
                payment must be done by credit card, and guests are entailed to
                provide their credit card number to complete the transaction.
                This, in turn, will be showed as a charge on your statement.
              </p>
            </li>
            <li>
              <h4 className="single-page-small-title">
                {" "}
                Confirmation of Payment{" "}
              </h4>
              <p>
                Once the payment is done, our travel consultants will send you a
                confirmation slip by e-mail . Its print can be produced as an
                evidence of payment to the service provider to redeem your tour
                package. But make sure that you´ve provided correct information
                pertaining to your travel requirements at the time of booking.{" "}
              </p>
            </li>
            <br />
            <li>
              <h4 className="single-page-small-title">
                {" "}
                Itinerary Amendments{" "}
              </h4>
              <p>
                Routings and services covered in your package are subject to
                change based on local / weather conditions, airway schedules and
                such other several aspects. Should this transpire, we can
                provide suitable options of similar value, however depending on
                its availability. At most, we announce changes in itinerary, if
                any, before departure. Please note that Hunjra Tourism & Travels
                reserves complete right to implement minor amendments in
                itinerary at any time without reimbursement. Further, no
                reimbursement can be done in the event of vis major, such as
                flood and earthquake.{" "}
              </p>
            </li>
            <br />
            <li>
              <h4 className="single-page-small-title">
                Hotel Accommodation & Check-in – Check-out Policy
              </h4>
              <p>
                If we are not able to trace for you an accommodation in your
                preferred hotel, all efforts will be undertaken to provide you
                with a suitable alternative of similar budget and standard.
                Speaking of check-in / check-out policy, it largely depends on
                the hotel that you´ve chosen. Hunjra Tourism & Travels cannot be
                held responsible for any early or late check-in and check-out
                requests which are not adhered by the hotel due to their busy
                schedule.{" "}
              </p>
            </li>
            <br />
            <li>
              <h4 className="single-page-small-title">Travel Insurance </h4>
              <p>
                Hunjra Tourism & Travels shall not be responsible for any kind of
                damages as an outcome of accident, illness, injury, or loss
              </p>
            </li>
            <br />
            <li>
              <h4 className="single-page-small-title">Travel Documents</h4>
              <p>
                It is the responsibility of every guest to ensure that he or she
                carries documents relevant for a particular tour, including
                passport or a valid ID card. This is especially important for
                guests coming from a different country. No refund can be done in
                the event of loss or lack of these relevant documents.
                Similarly, passengers – irrespective of their nationality – are
                advised to check with their respective country´s consulate here
                to gain information on entry requisites, before they plan to
                visit here. Equally vital is to inquire with your consulate
                regarding the current visa and health requirements, as they are
                subject to change without prior notice.
              </p>
            </li>
            <br />
            <li>
              <h4 className="single-page-small-title">
                Website Usage Restrictions
              </h4>
              <p>
                All content in this website, including logo, images, information
                on tour package, pricing details, and other relevant details,
                are proprietary to Hunjra Tourism and Travels. Accordingly, as a
                condition of this website´s usage, you agree not to exploit this
                website or its content for any non-personal, commercial, or
                illegitimate purposes.{" "}
              </p>
            </li>
            <br />
            <li>
              <h4 className="single-page-small-title">
                Delivery & Shipping Policy
              </h4>
              <p>
                Inclusion is mandatory and should have the below mentioned point
                (Applicable as per the website Products/Services). Recommended
                statement is “(Website) will NOT deal or provide any services or
                products to any of OFAC (Office of Foreign Assets Control)
                sanctions countries in accordance with the law of UAE”.{" "}
              </p>
            </li>
            <br />
            <li>
              <h4 className="single-page-small-title">
                {" "}
                Governing Law and Jurisdiction
              </h4>
              <p>
                Inclusion is mandatory and should have any one of the below
                mentioned points. “Any purchase, dispute or claim arising out of
                or in connection with this website shall be governed and
                construed in accordance with the laws of UAE”.{" "}
              </p>
            </li>
          </ul>
        </div>
      </div>

      <Subscribe />
      <Footer />
    </>
  );
};

export default Termsandconditions;
