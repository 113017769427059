const AbudabiVisaData = [
  {
    id: 1,
    visaType: "30 - DAYS TOURIST SINGLE ENTRY",
    insurance: "WITH INSURANCE",
    amount: "290.00",
    imgurl: "021",
  },
  {
    id: 2,
    visaType: "90 - DAYS TOURIST SINGLE ENTRY",
    insurance: "WITH INSURANCE",
    amount: "690.00",
    imgurl: "022",
  },
  {
    id: 3,
    visaType: " 30 - DAYS INSIDE COUNTRY",
    insurance: "WITH INSURANCE",
    amount: "980.00",
    imgurl: "023",
  },
  {
    id: 4,
    visaType: "90 - DAYS INSIDE COUNTRY",
    insurance: "WITH INSURANCE",
    amount: "1,300.00",
    imgurl: "024",
  },
  {
    id: 5,
    visaType: "30 - DAYS EXTENSION",
    insurance: " SAME COMPANY VISA",
    amount: "950.00",
    imgurl: "025",
  },
];
export default AbudabiVisaData;
