import React, { useEffect, useRef } from "react";
const StarRating = () => {
    const randomNumber =()=>{
        return ' (' + Math.floor(Math.random()*(999-100+1)+100) + ' reviews)';
    }
    return (
      <div className="star-rating">
        {[...Array(5)].map((star) => {        
          return (    
            <span className="star">&#9733;</span>
          );
        })}
<div style={{color: 'black', fontSize: '13px'}}> {randomNumber()}</div> 
      </div>
    );
  };
  export default StarRating
