
import { React, useState, useEffect, useMemo, useCallback, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import requests from "../utils/Requests";
import { useHistory } from "react-router-dom";
import axios from "axios";
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import { useParams } from "react-router";
import encryptStorage from "../utils/Encryptstorage";
// import { setGlobalState } from "./index"
import { useDispatch } from "react-redux";
import { addToCartData } from "../../redux/actions/cartActions"
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Swal from "sweetalert2";
// import {
//   Row,
//   Col,
//   Card,
//   Button,
//   CardHeader,
//   CardBody,
//   Label,
//   Input,
//   Alert,
// } from "reactstrap";
import './MainList.css'
import WarningNotificationContent from "./WarningNotificationContent";
import InfoNotificationContent from "./InfoNotificationContent";

const MainList = (props) => {
  let adultMenuItems = [];
    for (var i = 1; i <= 50; i++) {
      adultMenuItems.push( <MenuItem value={i}>{i}</MenuItem>)
    }
    let childMenuItems = [];
    for (var i = 0; i <= 50; i++) {
      childMenuItems.push( <MenuItem value={i}>{i}</MenuItem>)
    }
  const dispatch = useDispatch();
  let publicUrl = process.env.PUBLIC_URL + "/";
    // const { meta: {touched, error}, input, addonId, price } = props;  
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const columnDefs = [
    { headerName: "Ticket Type", field: "ticketType",  
    minWidth: 180,
    width: 200,
    cellStyle: {'white-space': 'normal', 'line-height': '20px'},
    // editable: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true },
    {
        headerName: "Tour Date",
        filter: false,
        minWidth: 175,
        width: 175,
        field: "bookTravelDate",
        cellRendererFramework: (params) => (
          <div>
             <LocalizationProvider dateAdapter={AdapterDateFns}>
<DatePicker
// label="Basic example"
minDate={params.value}
value={params.value}
onChange={(newValue) => {
  handleChangeDate(params, newValue);
}}
renderInput={(params) => <TextField {...params} />}
/>
</LocalizationProvider>
          </div>
        ),
      },
    {
      headerName: "Transfer Option",
      filter: false,
      minWidth: 210,
      width: 210,
      field: "transferOption",
      // editable: true,
      cellRendererFramework: (params) => (
        <div>
  <Select
  // style={{width: '170px'}}
    labelId="demo-select-small"
    id="demo-select-small"
    value={params.value}
    label="withTransfer"
    onChange={(newValue) => {
      handleChange(params, newValue);
    }}
  >
    {props.attractions && !(props.attractions.desert || props.attractions.cruise) &&
    <MenuItem value={0}>Without Transfer</MenuItem>}
    <MenuItem value={1}>Private Transfer</MenuItem>
    <MenuItem value={2}>Shared Transfer</MenuItem>
  </Select>
        </div>
      ),
    },
    {
      headerName: "Adult",
      filter: false,
      minWidth: 100,
      width: 100,
      field: "bookNoAdult",
      // editable: true,
      cellRendererFramework: (params) => (
        <div>
          {/* <input id="adult" {...params} defaultValue={params.value} */}
          {/* <TextField type="text" value ={params.value} defaultValue={1} 
          onChange={(newValue) => {
            handleChange(params, newValue);
          }}
          /> */}
          <Select
  // style={{width: '60px'}}
    labelId="demo-select-small"
    id="demo-select-small"
    value={params.value}
    label="bookNoAdult" defaultValue={params.value}
    onChange={(newValue) => {
      handleChange(params, newValue);
    }}
  >
    {adultMenuItems}
  </Select>
        </div>
      ),
    },
    {
      headerName: "Child",
      filter: false,
      minWidth: 100,
      width: 100,
      field: "bookNoChild",
      // editable: true,
      cellRendererFramework: (params) => (
        <div>
          {/* <TextField id="child" value ={params.value} defaultValue={params.value}
          onChange={(newValue) => {
            handleChange(params, newValue);
          }}
          /> */}

<Select
  // style={{width: '60px'}}
    labelId="demo-select-small"
    id="demo-select-small"
    value={params.value}
    label="bookNoChild" defaultValue={params.value}
    onChange={(newValue) => {
      handleChange(params, newValue);
    }}
  >
    {childMenuItems}
  </Select>
        </div>
      ),
    },
    {
      headerName: "Total Amount",
      filter: false,
      minWidth: 100,
      width: 100,
      field: "bookTotal",
      editable: false,
      // cellRendererFramework: (params) => (
      //   <div> {params.value}
      //     {/* <Label id="adult" {...params} defaultValue={params.value}/> */}
      //   </div>
      // ),
    },
  ];
     const onGridReady = e => {
      // console.log('e.api:::', e.api)
      setApi(e.api)
      e.api.sizeColumnsToFit();
      e.columnApi.resetColumnState();
  }
  const gridOption = {
    rowData: [],
    columnDefs: columnDefs,
    pagination: true,
    rowSelection: 'multiple',
    // onRowClicked: event => console.log('A row was clicked'),
    // onColumnResized: event => console.log('A column was resized'),
    onGridReady: onGridReady,
    suppressRowClickSelection: true
    // suppressCellFocus: true
    // getRowHeight: (params) => 25
    }
    const [api, setApi] = useState(null);

    // const [tkttypeTemp, settkttypeTemp] = useState([]);
    // const [apiTicketType, setApiTicketType] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [gridOptions, setGridOptions] = useState(gridOption);
    const [LoggedIn, setLoggedIn] = useState(null);
    const { id } = useParams();
    const [agency, setagency] = useState(0);
    const [customer, setcustomer] = useState(0);
    const [agencyuser, setagencyuser] = useState(0);
    const [userdata, setuserdata] = useState([]);
    const [tourpack, setTourpack] = useState([]);
    const [priceData, setPriceData] = useState(new Map());
    const [priceDataLoding, setPriceDataLoding] = useState(false);
    const [selectRows, setSelectRows] = useState([]);
    const [userCart, setUserCart] = useState(new Set());
    const [userCartWarningMsg, setUserCartWarningMsg] = useState("");
    const [userCartSuccessMsg, setUserCartSuccessMsg] = useState("");
    const [showAlert, setshowAlert] = useState(0);
const [showAlertResponse, setShowAlertResponse] = useState(null);
const [notAvailableTicket, setNotAvailableTicket] = useState(null);
    useEffect(() => {
      let login = sessionStorage.getItem("isLoggedIn");
      // console.log('login::::', login)
      // console.log('sessionStorage::::', sessionStorage)
      // const enl = encryptStorage.getItem("enl"); //obj //agency.agencyId
      // console.log('enl::::', enl)
      // let urlObject = JSON.parse(enl);
      // setuserdata(urlObject);
      console.log('priceData::::', priceData)

    if (login == "yes") {
      const enl = encryptStorage.getItem("enl"); //obj //agency.agencyId
      // console.log('enl::::', enl)
      let urlObject = JSON.parse(enl);
      setuserdata(urlObject);
      // console.log('urlObject::::', urlObject)

      if (urlObject.b2b == true) {
        let agencyId = urlObject.agency.agencyId;
        let agency = urlObject.agency;
        // console.log('agency::::=', agency)  

        setagency(agency);
        getTourDeail(agencyId, 0);
        getCartData(agencyId, 0)
        setLoggedIn(1);
      } else if (urlObject.b2bUser == true) {
        // console.log('agency::::=else if', agency)  
        let agencyuserId = urlObject.agencyUser.agencyUserId;
        let agencyuser = urlObject.agencyUser;

        setagencyuser(agencyuser);
        getTourDeail(0, agencyuserId);
        getCartData(agencyuser, 1)
        setLoggedIn(1);
      } else if (urlObject.b2c == true) {
        // console.log('agency::::=else if', agency)  
        let customerId = urlObject.customer.customerId;

        setcustomer(urlObject.customer);
        getTourDeail(0, 0);
        getCartData(customerId, 2)
        setLoggedIn(1);
      } else {
        // console.log('agency::::=else', agency)  
        // setuserId(urlObject.customer.customerId);
        // setb2cUser(1);
        getTourDeail(0, 0);
        getCartData(0, 0)
        setLoggedIn(0);
        // setOpenTicket(2);
        //  props.initialize({});
      }
    } else {
      // setOpenTicket(2);
      getTourDeail(0, 0);
      // getCartData(0)
      setLoggedIn(0);
    }    
    }, []);
    useEffect(() => {
      if(LoggedIn !== null) {
        if(id !== undefined) {
          apicalltkt(id);
        } else if(props.attractionsId != null && props.attractionsId != undefined){
          apicalltkt(props.attractionsId);
        }
      }
    }, [LoggedIn]);
    useEffect(() => {
      // console.log('priceData:::::====++++', priceData)
      if(api) {
        // console.log('api:::::====', api)
        // console.log('rowData:::::====', rowData)
        // console.log('priceData:::::====', priceData)
        // console.log('priceDataLoding:::::====', priceDataLoding)

if(rowData.length !== 0  && priceData.size !== 0 && rowData.length === priceData.size) {
  rowData.forEach(obj=>{
    const price = priceData.get(obj.ticketTypeId)
    if(price) {
      const total = price.adultfare * obj.bookNoAdult +  price.childfare * obj.bookNoChild
      obj.adultfare = price.adultfare
      obj.childfare = price.childfare
      obj.bookTotal = total
      if(obj.transferOption === 1 && !(props.attractions.desert || props.attractions.cruise)) {
        obj.bookTotal = obj.bookTotal + (obj.bookNoAdult + obj.bookNoChild) *150
      } else if(obj.transferOption === 2 && !(props.attractions.desert || props.attractions.cruise)) {
        obj.bookTotal = obj.bookTotal + (obj.bookNoAdult + obj.bookNoChild) * 100
      }
      if(obj.transferOption === 1 && (props.attractions.desert)) {
        const initialAmount = 800
          const extraAmount = ((obj.bookNoAdult + obj.bookNoChild) - 1) * 100
          obj.bookTotal = obj.bookTotal + initialAmount + extraAmount
      } else if(obj.transferOption === 1 && (props.attractions.cruise)) {
        const initialAmount = 300
          const extraAmount = ((obj.bookNoAdult + obj.bookNoChild) - 1) * 70
          obj.bookTotal = obj.bookTotal + + initialAmount + extraAmount
      }
    }
  })
  setRowData(rowData)
  console.log('rowData:::::====---', rowData)
  api.setRowData(rowData)
}
      }     
    }, [priceData, priceDataLoding]);
    const gettktDisPrice = async (val, dataLength) => {
      let adultfare = 0
      let childfare = 0
      const enl = encryptStorage.getItem("enl"); //obj //agency.agencyId
      // console.log('enl::::', enl)
      let urlObject = enl ? JSON.parse(enl) : null;
      await axios
        .post(requests.getAttractionTicketTypePrice, {
          ticketTypeId: val,
          agencyId: agency.agencyId,
          agencyUserId: agencyuser.agencyUserId,
          attractionsId: id != undefined ? id: props.attractionsId,
        })
        .then((res) => {
          // errorCode 505 No Adult Ticket
          // errorCode 504 No Child Ticket
          // console.log("Ticket Price", res.data);
          // console.log("loggedIn:::===", LoggedIn)

          if (LoggedIn == 1) {
            // for b2b

            // console.log("loggedIn:::", LoggedIn)
            if (res.data.errorCode == 505) {
              // setshowTktFare(1);
              // setservserRes("Ticket Not Available");
              // settktAvailability(1);
            } else if (res.data.errorCode == 504) {
              if (res.data.b2bAdultDisPrice == 0) {
                adultfare = res.data.b2bAdultPrice; //b2bChildDisPrice
                childfare = 0;
                // setshowTktFare(1);
                // setservserRes("Child Ticket Not Available");
                // settktAvailability(1);
              } else {
                adultfare = res.data.b2bAdultDisPrice;
                childfare = 0;
                // setshowTktFare(1);
                // setservserRes("Child Ticket Not Available");
                // settktAvailability(1);
              }
            } else {
              if (urlObject && urlObject.b2c !== true) {
              if (res.data.b2bAdultDisPrice == 0) {
                adultfare = res.data.b2bAdultPrice; //b2bChildDisPrice
                childfare = res.data.b2bChildPrice;
              } else {
                adultfare = res.data.b2bAdultDisPrice; //b2bChildDisPrice
                childfare = res.data.b2bChildDisPrice;
              }
            } else {
              adultfare = res.data.b2cAdultPrice;
              childfare = res.data.b2cChildPrice;
            }
            }
          } else {
            //for b2c
            // console.log('b2c:::')
            if (res.data.errorCode == 505) {
              // setshowTktFare(0);
              // setservserRes("Ticket Not Available");
              // settktAvailability(1);
            } else if (res.data.errorCode == 504) {
              adultfare = res.data.b2cAdultPrice; //b2bChildDisPrice
              childfare = 0;
              // setshowTktFare(1);
              // setservserRes("Child Ticket Not Available");
              // settktAvailability(1);
            } else {
              adultfare = res.data.b2cAdultPrice;
              childfare = res.data.b2cChildPrice;
              // setshowTktFare(1);
              // settktAvailability(0);
            }
          }
        // console.log('adultfare:::', adultfare)
        // console.log('childfare:::', childfare)
        const price = {
          adultfare: adultfare,
          childfare: childfare
        }
        // console.log('price:::', price)
        // console.log('val:::', val)
      //  const priceData1 = priceData
      priceData.set(val, price)
        // console.log('priceData:::', priceData)
        setPriceData(priceData)
        // console.log('priceData:::', priceData)
        // console.log('rowData:::==', rowData)
        if(priceData.size !== 0 && priceData.size === dataLength) {
          setPriceDataLoding(true)
        }
          //   console.log('setPriceDataLoding:::---', priceDataLoding)
          // if(priceDataLoding) {
          //   console.log('setPriceDataLoding:::', false)
          //   setPriceDataLoding(false)
          // } else {
          //   console.log('setPriceDataLoding:::', true)
          //   setPriceDataLoding(true)
          // }
        // setPriceDataLoding(!priceDataLoding)
        // console.log('setPriceDataLoding:::', true)
        // console.log('priceData:::', priceData)
        })
        .catch((err) => {});
        // console.log('adultfare:::==', adultfare)
        // console.log('childfare:::', childfare)

    }
    const getCartData = async (b2buserid, userType) => {
      await axios
        .post(requests.getUserCartListAll, {
          userId: b2buserid,
          userType: userType
        })
        .then((res) => {
          const set1 = new Set();
          res.data.forEach(obj=>{
            set1.add(obj)
          })
          setUserCart(set1);
          // setGlobalState('cartList', set1)
          dispatch(addToCartData(set1))
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const getTourDeail = async (b2bid, b2buserid) => {
      await axios
        .post(requests.getAttractionDetails, {
          attractionsId: id != undefined ? id: props.attractionsId,
          agencyId: b2bid,
          agencyUserId: b2buserid,
        })
        .then((res) => {
          setTourpack(res.data);
  
          // console.log(res.data);
  
          //attConnectWithApi
          // const [OpenTicket, setOpenTicket] = useState(1);
          if (res.data.attConnectWithApi == true) {
            // setOpenTicket(2);
          }
  
          // setisLoading(false);
          // settktfare(tourpack.price)
          // console.log(res.data);
          // if (LoggedIn == 1) {
          //   setadultfare(res.data.b2bAdultPrice);
          //   setchildfare(res.data.b2bChildPrice);
          // } else {
          //   setadultfare(res.data.b2cAdultPrice);
          //   setchildfare(res.data.b2cChildPrice);
          // }
          // atID = res.data.attGroup;
          // getAttAllByGroup(atID);
          // sessionStorage.setItem("attname", res.data.attName);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    const apicalltkt = (attId) => {
      let login = sessionStorage.getItem("isLoggedIn");
      let tempObj = null;
      if (login == "yes") {
        const enl = encryptStorage.getItem("enl"); //obj //agency.agencyId
        let urlObject = JSON.parse(enl);
        tempObj = urlObject;
        // console.log('tempObj:::', tempObj)
      }
      axios
        .post(requests.getTicketTypeListByAttraction, { ttAttractionId: attId })
        .then((res) => {  
          const apiTicket = [];
            (res.data.forEach(obj => {
            gettktDisPrice(obj.ticketTypeId, res.data.length)
            let travelDate = new Date();
            // console.log('===========', travelDate.getHours())
            if(tourpack && tourpack.desert) {
              if(travelDate.getHours() >= 14) {
                travelDate= travelDate.setDate(travelDate.getDate() + 1);
              }
              console.log('===========', travelDate)
            }
            let transferOption = 0
            if(props.attractions && (props.attractions.desert || props.attractions.cruise)) {
              transferOption = 2
            }
            let userId = 0
            let userType = 0
            if(tempObj && tempObj.agency && tempObj.agency.agencyId) {
              userId = tempObj.agency.agencyId
              userType = 0
            } else if(tempObj && tempObj.agencyUser && tempObj.agencyUser.agencyUserId) {
              userId = tempObj.agencyUser.agencyUserId
              userType = 1
            } else if(tempObj && tempObj.customer && tempObj.customer.customerId) {
              userId = tempObj.customer.customerId
              userType = 2
            }
            apiTicket.push({
              userId: userId,
              userType: userType,
              ticketTypeId: obj.ticketTypeId,
              attractionId: attId,
              ticketType: obj.ttTicketType,
              transferOption: transferOption,
              bookTravelDate: travelDate,
              bookNoAdult: 1,
              bookNoChild: 0,
              adultfare: 0,
              childfare: 0,
              bookTotal: "",
              activeStatus: true
              // eventtypeId: res.data[i].eventtypeId,
              // resourceID: res.data[i].resourceID,
            });
          }))
          setRowData(apiTicket);
        })
        .catch((err) => {
          console.log(err);
        });
    };
      const handleChange = (params, newValue) => {
      const colId = params.column.colId
      const data = params.data
      data[colId] = newValue.target.value
      let total = data.adultfare * data.bookNoAdult +  data.childfare * data.bookNoChild
        if(data.transferOption === 1 && !(props.attractions.desert || props.attractions.cruise)) {
          total = total + + (data.bookNoAdult + data.bookNoChild) * 150
        } else if(data.transferOption === 2 && !(props.attractions.desert || props.attractions.cruise)) {
          total = total + + (data.bookNoAdult + data.bookNoChild) * 100
        }
        if(data.transferOption === 1 && (props.attractions.desert)) {
          const initialAmount = 800
          const extraAmount = ((data.bookNoAdult + data.bookNoChild) - 1) * 100
          total = total + initialAmount + extraAmount
        } else if(data.transferOption === 1 && (props.attractions.cruise)) {
          const initialAmount = 300
          const extraAmount = ((data.bookNoAdult + data.bookNoChild) - 1) * 70
          total = total + initialAmount + extraAmount
        }
        data.bookTotal = total  
      let rowNode = params.api.getRowNode(params.rowIndex)
      rowNode.setData(data)
    };

    const handleChangeDate = (params, newValue) => {
      const colId = params.column.colId
      const data = params.data
      data[colId] = new Date(newValue)
      let rowNode = params.api.getRowNode(params.rowIndex)
      rowNode.setData(data)
    };
 

  const onSelectionChanged  = (event) => {
      console.log('onSelectionChanged:::', event.api.getSelectedRows())
      setSelectRows(event.api.getSelectedRows())
  }
  const addToCart  = (event) => {
    console.log('setSelectRows:::', selectRows)
    selectRows.forEach(obj=>{
      let objectFound = null
      for (const item of userCart) {
        if(String(item.attractionId) === String(obj.attractionId) && item.ticketTypeId === obj.ticketTypeId) {
          objectFound = item
        }
      }
      if(objectFound){
        setUserCartWarningMsg(objectFound.ticketType + " is already in cart. Please un check it")
      } 
      // else if(LoggedIn == 0) {
      //   setUserCartSuccessMsg("Added details into cart successfully")
      //   let userCart1 = userCart
      //         selectRows.forEach(obj=>{
      //           userCart1.add(obj)
      //         })
      //         setUserCart(userCart1);
      //         console.log('userCart:::=', userCart1);
      //         dispatch(addToCartData(userCart1))
      // }
      else {
        selectRows.forEach(obj=>{
          checkTicketAvailabilty(obj)
        })
      }
    })
}
const checkTicketAvailabilty = async (obj) => {  
  console.log('obj:::::', obj)
    await axios
      .post(requests.getAttractionTicketTypePrice, {
        ticketTypeId: obj.ticketTypeId,
        agencyId: agency.agencyId,
        agencyUserId: agencyuser.agencyUserId,
        attractionsId: obj.attractionId,
      })
      .then((response) => {
        setShowAlertResponse(response.data)
        setNotAvailableTicket(obj)
        encryptStorage.setItem("response.data", response.data);
        
        if (response.data.errorCode == 505 || response.data.errorCode == 504) {
          setshowAlert(5);
        } else {
          setUserCartSuccessMsg("Added details into cart successfully")
          console.log("selectRows:::",selectRows)       
          
                console.log('userCart:::', userCart);
                // const set1 = new Set();
                let userCart1 = userCart
                const selectRowsLocal = []
                selectRows.forEach(obj=>{
                  console.log('obj:::=', obj);
                  obj['combo'] = false
                  selectRowsLocal.push(obj)
                  userCart1.add(obj)
                })
                setUserCart(userCart1);
                console.log('userCart:::=', selectRowsLocal);
                // setGlobalState('cartList', userCart1)
                dispatch(addToCartData(userCart1))
                axios
                .post(requests.setUserCartDetails, selectRowsLocal)
                .then((response) => {
                  // console.log("response:::",response)            
                })
                .catch((error) => {
                  console.log(error);
                });
        }
      })
      .catch((error) => {
        console.log(error);
        setshowAlert(0);
      });
    // })
}
useEffect(()=>{
  if (showAlert == 1) {
    successAlert(
      "Please Wait",
      "Processing",
      publicUrl + "assets/img/formsubmit.gif"
      //publicUrl publicUrl + "assets/img/others/about1.jpeg"
    );
  }
  if (showAlert == 2) {
    successAlert("Ticket Not Avilable", showAlertResponse.ticketType + " Ticket Not Available. Please remove from your cart");
  }
  
  if (showAlert == 3) {
    successAlert("Ticket Not Avilable", showAlertResponse.ticketType + " Child  Ticket Not Available. Please remove from your cart");
  }
  if (showAlert == 4) {
    successAlert("Technical Problem", "Something went wrong");
  }
  if (showAlert == 5) {
    successAlertWithOk("Ticket Not Avilable"  , showAlertResponse.errorMessage + ". Shall we move to enquiry, and please contact our representative.","", true);
  }
  }, [showAlert])

const successAlert = (title, text, url, boolValue) => {
  Swal.fire({
    title: title,
    text: text,
    imageUrl: url,
    imageWidth: 400,
    imageHeight: 200,
    imageAlt: "Custom image",
    showCancelButton: false, // There won't be any cancel button
    showConfirmButton: boolValue, // There won't be any confirm button
  });
};
const successAlertWithOk = (title, text, url, boolValue) => {
  Swal.fire({
    title: title,
      text: text,
    showDenyButton: false,
    showCancelButton: true,
    confirmButtonText: 'OK',
    denyButtonText: `CANCEL`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      // Swal.fire('Saved!', '', 'success')
          //no adult tkt
          let cutomerName = ''
          let bookCustomerEmail = ''
          let bookMobileNumber = ''
          if(agency) {
            cutomerName = agency.agencyName
            bookCustomerEmail = agency.agencyEmail
          } else if(agencyuser) {
            cutomerName = agencyuser.userFirstName
            bookCustomerEmail = agencyuser.userEmail
          } else if(customer) {
            cutomerName = customer.cutomerName
            bookCustomerEmail = customer.eMailId
          }
          const submitdata = {
            bookB2bId: agency.agencyId,
            bookB2bUserId: agencyuser.agencyUserId,
            bookB2cId: customer.customerId,
            attractionsId: notAvailableTicket.attractionsId,
            bookCustomerName: cutomerName,
            bookCustomerEmail: bookCustomerEmail,
            bookMobileNumber: bookMobileNumber,
            ticketTypeId: notAvailableTicket.ticketTypeId,
            bookNofAdult: notAvailableTicket.bookNofAdult,
            bookNofChild: notAvailableTicket.bookNofChile,
            bookAdultPrice: 0,//adultfare,
            bookChildPrice: 0,//childfare,
            bookTravellDate: notAvailableTicket.bookTravellDate,
            bookPaymentMode: notAvailableTicket.bookPaymentMode,
            bookTotal: notAvailableTicket.total,
            // bookingAddon: 0,//bookingAddon,
            cartList: [notAvailableTicket],
            remarks: notAvailableTicket.remarks
          };
          const paymentData = {
            paymentAgencyId: agency.agencyId,
            paymentAgencyUserId: agencyuser.agencyUserId,
            paymentB2cId: customer.customerId,
            paymentCustomerName: cutomerName,
            paymentRemarks: "Test Pay",
            paymentAmount: 0,
            booking: submitdata
          };
              axios
          .post(requests.setenquiryBooking, paymentData)
          .then((response) => {
            console.log("response response:::", response)

            if(response.status === 202){
              // getCartData(userId)
            // setshowAlert(5);
            Swal.fire('Ticket Enquiry sent Success', '', 'info')
                }
            })
            .catch((error) => {
              console.log(error);
            });
            setshowAlert(0)
    } else if (result.isDismissed) {
      setshowAlert(0)
      Swal.fire('Changes are not saved', '', 'info')
    }
  })
};
const handleMessageClose = () =>{
  setUserCartWarningMsg("")
  setUserCartSuccessMsg("")
}
  const defaultColDef = {
    sortable: true,
    editable: false,
    flex: 1,
    filter: false,
    floatingFilter: false,
    resizable: true,
    autoHeight: true,
    wrapHeaderText: true,
    // wrapText: true
  };

  const gridRef = useRef();
  // const onFirstDataRendered = useCallback((params) => {
  //   gridRef.current.api.sizeColumnsToFit();
  // }, []);
    return ( <>
      <div style={{ height: ((rowData.length * 20) + 120 + "px").toString(), width: '100%' }}>
       
    {/* <div className="ag-theme-alpine" style={{ height: '100%' , width: '150px'}}> */}

    <div style={gridStyle} className="ag-theme-alpine">
               <AgGridReact
            // ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            rowSelection={'multiple'}
            onGridReady={onGridReady}
            pagination={false}
            onSelectionChanged={onSelectionChanged}
            // onFirstDataRendered={onFirstDataRendered}
          ></AgGridReact>
        </div>
        </div>
          <div style={{marginTop: '1%', paddingBottom: '1%'}} className='addToCartBtn'>
          <Button className='btn-yellow' 
          startIcon={<AddShoppingCartIcon/>}
          disabled={selectRows && selectRows.length === 0} variant="contained" onClick={addToCart}>ADD TO CART</Button>
          </div>
          <WarningNotificationContent message={userCartWarningMsg}
          handleMessageClose={handleMessageClose}  />
          <InfoNotificationContent message={userCartSuccessMsg}
          handleMessageClose={handleMessageClose}  />
    </> ); 
}
 
export default MainList;
