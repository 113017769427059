import { BrowserRouter } from "react-router-dom";
import WebIndex from "./components/web_index";
import { Provider } from "react-redux";
import store from "./components/utils/store";

function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <WebIndex />
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
