import { ADD_TO_CART, REMOVE_TO_CART } from "../actions/cartActions";
const initialState = {
    initial: String
  }
export const cartReducers = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_CART:
      return {
        ...state,
        cartData: action.cartData
      };
      case REMOVE_TO_CART:
        // console.log('action.cartData:::', action.cartData)
        // console.log('state.cartData:::', state.cartData)
        let cartDataList = [...state.cartData]
        const newArray = cartDataList.filter(obj => !(obj.attractionId === action.cartData.attractionId && obj.ticketTypeId === action.cartData.ticketTypeId));
        // console.log('newArray:::===', newArray)
    return {
      ...state,
      cartData: new Set(newArray)
    };
    default:
      return state;
}
}