const NODE_ENV = process.env.NODE_ENV || 'production'
// export const BASE_URL = 'http://localhost:8443/'; // local
export const BASE_URL = 'https://www.hunjratourism.com'; // production
// export const BASE_URL = 'http://199.192.31.213:8080'; // production
export const FILE_STORE_BASE_URL = BASE_URL + '/filestorage/';
 export const IMAGES_PATH_URL = FILE_STORE_BASE_URL + 'tourapp/images/'; //production
// export const IMAGES_PATH_URL = '/home/rider/STUDY/GOPI/tourapp/images/'; // local
//    export const IMAGES_PATH_URL = FILE_STORE_BASE_URL + 'tourapp/images/'; // local
export const INVOICE_PATH_URL = FILE_STORE_BASE_URL + 'tourapp/invoice/'; //production
// export const INVOICE_PATH_URL = '/home/rider/STUDY/GOPI/tourapp/invoice/'; // local

export const CONTACT_DETAILS1 = "210 Corporate Center Bullding"
export const CONTACT_DETAILS2 ="Kuwait St - Al Mankhool - Bur Dubai"
export const CONTACT_DETAILS3= " Dubai-UAE"
export const CONTACT_NUMBER1 = "+971 58 223 5210"
export const CONTACT_NUMBER2= "+971 42864448"
export const CONTACT_MAIL = "info@hunjratourism.ae"
export const COMPANY_NAME= "Hunjra Tourism L.L.C "
