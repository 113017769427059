import { Row, Col, Alert } from "reactstrap";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import encryptStorage from "../../utils/Encryptstorage";
import axios from "axios";
import requests from "../../utils/Requests";
import Swal from "sweetalert2";
import { FILE_STORE_BASE_URL } from '../../utils/constants'

const ApiTicketConfirmation = (props) => {
  
  let attname;

  const [bookdetails, setbookdetails] = useState([]);
  const [paymentPortel, setpaymentPortel] = useState("");

  const [paymentTransactionId, setpaymentTransactionId] = useState("");
  const [isloading, setisloading] = useState(true);
  const [urlObj, seturlObj] = useState();
  const [bookingObj, setbookingObj] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);


    setTimeout(function () {
      const url = encryptStorage.getItem("url"); //obj
      let urlObject = JSON.parse(url);
  
      // console.log("urlObject", urlObject);
      seturlObj(urlObject);
      setpaymentTransactionId(urlObject.paymentTransactionId);
      setpaymentPortel(urlObject.paymentUrl);
  
      const obj = encryptStorage.getItem("DFuck");
      let bookingObject = JSON.parse(obj);
      setbookingObj(bookingObject);
      // console.log("BookingObject", bookingObject);
      setbookdetails(bookingObject);
  
      // console.log("Url Object", urlObject);
      // console.log("paymentPortel", paymentPortel);
      // console.log("paymentTransactionId", paymentTransactionId);
      setisloading(false);
    }, 1000);

    // console.log("sdsfsfsfsfsfs", urlObject);
    // console.log("sdsfsfsdfdfdfd", bookingObject);
  }, []);

  attname = sessionStorage.getItem("attname");

  //paymentUrl, paymentTransactionId

  let history = useHistory();

  const [errMessage, seterrMessage] = useState("");
  const [showErrMsg, setshowErrMsg] = useState(false);
  const [alertStyle, setalertStyle] = useState("");



  const bookBurjTicket = () => {
    setalertStyle("primary");
    seterrMessage("Please Wait...");
    setshowErrMsg(true);

    const bookTicket = {
      attractionId: urlObj.attractionId,
      agencyId: urlObj.agencyId,
      agencyUserId: urlObj.agencyUserId,
      bookB2cId: urlObj.bookB2cId,
      customerName: urlObj.customerName,
      customerEmail: urlObj.customerEmail,
      customerMobileNumber: bookingObj.bookMobileNumber,
      eventId: urlObj.eventId,
      eventName: urlObj.eventName,
      eventTypeId: urlObj.eventTypeId,
      resourceId: urlObj.resourceId,
      startDateTime: urlObj.startDateTime,
      endDateTime: urlObj.endDateTime,
      available: urlObj.available,
      status: urlObj.status,
      nofAdult: urlObj.nofAdult,
      nofChild: urlObj.nofChild,
      nofInfant: urlObj.nofInfant,
      adultPrice: urlObj.adultPrice,
      childPrice: urlObj.childPrice,
      bookingId: urlObj.bookingId,
      invoiceNo: urlObj.invoiceNo,
      totalPrice: urlObj.totalPrice,
      bookPaymentMode: bookingObj.bookPaymentMode,
      bookPaymentRefId: urlObj.bookPaymentRefId,
      ticketTypeId: bookingObj.ticketTypeId,
      bookingAddon: bookingObj.bookingAddon,

      //   customerName: agencyId.customerName,
      //   bookingId: urlObj.bookingId,
    };
    // console.log("bookTicket", bookTicket);

    if (bookingObj.bookPaymentMode == 1) {
      setalertStyle("danger");
      seterrMessage(
        "This Ticket Only Available For Agents, Kindly Login Using Your Agent ID"
      );
    } else {
      // axios
      //   .post(requests.confirmBurjTicket, bookTicket)
      //   .then((response) => {
      //    console.log(response.data);
      //     if (response.data.errCode == 700) {
      //       setalertStyle("danger");
      //       seterrMessage(response.data.errMessage);
      //       setshowErrMsg(true);
      //     } else if(response.data.errCode == 100){
      //       setalertStyle("danger");
      //       seterrMessage("insufficient Balance");
      //       setshowErrMsg(true);
      //     }else if (response.data.errCode == 0) {
      //       const fileURL =
      //         "FILE_STORE_BASE_URL" +
      //         response.data.ticketFilePath;
      //       const filename = response.data.ticketFilePath;

      //       Swal.fire({
      //         title: "Booking Confirmed",
      //         text: "Thank You For Your Booking, press OK to download your e-Ticket",
      //         showDenyButton: false,
      //         showCancelButton: false,
      //         confirmButtonText: "Ok",
      //         denyButtonText: `Don't save`,
      //       }).then((result) => {
      //         /* Read more about isConfirmed, isDenied below */
      //         if (result.isConfirmed) {
      //           download_file(fileURL, filename);
      //         }
      //       });

      //       setshowErrMsg(false);
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }

    axios
    .post(requests.confirmBurjTicket, bookTicket)
    .then((response) => {
     console.log("Responce data",response.data);
      if (response.data.errCode == 700) {
        setalertStyle("danger");
        seterrMessage(response.data.errMessage);
        setshowErrMsg(true);
      } else if(response.data.errCode == 100){
        console.log("Insufficiend balance")
        setalertStyle("danger");
        seterrMessage("insufficient Balance");
        setshowErrMsg(true);
      }else if(response.data.errCode == 222){
        setalertStyle("danger");
        seterrMessage(response.data.errMessage);
        setshowErrMsg(true);
      }
      else if (response.data.errCode == 0) {
        const fileURL =  FILE_STORE_BASE_URL +
          response.data.ticketFilePath;
        const filename = response.data.ticketFilePath;

        Swal.fire({
          title: "Booking Confirmed",
          text: "Thank You For Your Booking, press OK to download your e-Ticket",
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "Ok",
          denyButtonText: `Don't save`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            download_file(fileURL, filename);
          }
        });

        setshowErrMsg(false);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };

  function download_file(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
    history.goBack();
  }
  return (
    <>
      <Row>
        <Col xs={2}></Col>
        <Col xs={8}>
          <div className="container rounded bg-white">
            <div className="d-flex justify-content-end">
              <a class="cancel com-color" onClick={history.goBack}>
                Cancel and return to website
              </a>
            </div>
            <div className="d-flex justify-content-center">
              <h4 className="single-page-small-title">Your Booking Details</h4>
            </div>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Park Name </h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? <p>Please Wait</p> : attname}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Booking Customer Name</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookCustomerName
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Your Email</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookCustomerEmail
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Contact</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookMobileNumber
                  )}
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Ticket Type</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">{ctkttype}</div>
              </Col>
            </Row> */}
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Number Of Adult</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookNofAdult
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Number Of Child</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {bookdetails.bookNofChild == null ? (
                    0
                  ) : isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookNofChild
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Date</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookTravellDate
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Time</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    urlObj.startDateTime 
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Total Amount</h6>
                </div>
              </Col>

              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookTotal
                  )}
                </div>
              </Col>
            </Row>
            <br />
            {showErrMsg && <Alert color={alertStyle}>{errMessage}</Alert>}

            <Row>
              <Col xs={6}></Col>

              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <div class="buttons">
                    {" "}
                    {isloading == true ? null : (
                      <button
                        class="btn btn-success2 btn-block "
                        type="submit"
                        onClick={bookBurjTicket}
                      >
                        Proceed To Book
                      </button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={2}></Col>
      </Row>

      {/* <button onClick={()=>testPaymentform()}>Test Payment</button> */}
    </>
  );
};

export default ApiTicketConfirmation;
