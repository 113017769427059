// import * as React from 'react'
import { React, useState, useEffect, useMemo, useCallback } from "react";
import { connect } from 'react-redux'
import Footer from "../global-components/footer";
import TopNav from "../global-components/top_nav";
import CartSubmit from "./CartSubmit";
import CartView from "./CartView"
import encryptStorage from "../../utils/Encryptstorage";
import { Link } from "react-router-dom";
import axios from "axios";
import requests from "../../utils/Requests";
import { INVOICE_PATH_URL, IMAGES_PATH_URL } from "../../utils/constants";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import './ShowCart.css'
const ShowCart = (props) => {
  const [loggedIn, setLoggedIn] = useState();
  const [paymentStatus, setPaymentStatus] = useState('');
  const [popUp, setPopUp] = useState(false);
  const [transId, setTransId] = useState({
    TransID: "",
    CompanyRef: ""
  });
  useEffect(() => {
  let login = sessionStorage.getItem("isLoggedIn");
    if (login == "yes") {
      const enl = encryptStorage.getItem("enl");
      let urlObject = JSON.parse(enl);
      if (urlObject.b2b == true) {
        setLoggedIn(1);
      } else if (urlObject.b2bUser == true) {
        setLoggedIn(1);
      }  else if (urlObject.b2c == true) {
        setLoggedIn(1);
      }else {
        setLoggedIn(0);
      }
    } else {
      setLoggedIn(0);
    }
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);  
    const TransID = params.get('TransID')
    const CCDapproval = params.get('CCDapproval')
    const PnrID = params.get('PnrID')
    const TransactionToken = params.get('TransactionToken')
    const CompanyRef = params.get('CompanyRef')
    console.log('params:::', params.get('TransID'))
    console.log('windowUrl:::', windowUrl)
    if(TransID && CCDapproval && PnrID && TransactionToken && CompanyRef) {
      setTransId(
        {
        TransID: TransID,
        CompanyRef: CompanyRef
      })
      console.log('windowUrl:::====')
      const xmlBodyStr = `<?xml version="1.0" encoding="utf-8"?>
      <API3G>
        <CompanyToken>2220D4D4-D2DB-447F-8922-6F41A6F83D94</CompanyToken>
        <Request>verifyToken</Request>
        <TransactionToken>${TransactionToken}</TransactionToken>
      </API3G>`;
      const config = {
        headers: {'Content-Type': 'text/xml'}
   };
   getApiCall(TransactionToken, TransID)
  //       axios
  //         .post(requests.paymentValidate, {
  //           transToken: TransactionToken
  //         })
  //         .then((res) => {
  //           console.log(
  //             "Response", res
  //           )
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
      
    }
  }, []);
  const getApiCall = async (TransactionToken, TransID) => {
    console.log('==========')
    await axios
      .post(requests.paymentValidate, {
        transToken: TransactionToken,
        paymentTransactionId: TransID
      })
      .then((res) => {
        console.log(
          "Response", res.data
        )
        if(res.data) {
        setPaymentStatus(res.data)
        setPopUp(true)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const ticketOpen =(type) =>{
    // event.preventDefault();
    if(paymentStatus && paymentStatus.bookingList && paymentStatus.bookingList.length !== 0) {
      console.log('paymentStatus.bookingList:::', paymentStatus.bookingList)
      let invoice = ""
      paymentStatus.bookingList.forEach(obj=>{
        if(type === 0) {
        // window.open(IMAGES_PATH_URL + obj.bookingTickPdfPath)
        download_file(IMAGES_PATH_URL + obj.bookingTickPdfPath, obj.bookingTickPdfPath)
        } else {
          invoice = obj.bookNumber + "-"+ obj.xeroInvoiceNumber + ".pdf"
        }
      })
      if(type === 1 && invoice) {
        // window.open(invoice)
        download_file(INVOICE_PATH_URL + invoice, invoice)
      }
    }
  }
  function download_file(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }
  
    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }
  const invoiceOpen =(event) =>{
    event.preventDefault();
    if(paymentStatus && paymentStatus.bookingList && paymentStatus.bookingList.length !== 0) {
      paymentStatus.bookingList.forEach(obj=>{
        window.open(obj.bookingTickPdfPath)
      })
    }
  }
  const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  return (
    <>
      <TopNav />
      <br/>
      <br/>
      <br/>
      <br/>
      {props.cartDataList && props.cartDataList.length > 0 ? (
      <div className="row">
        <div className="col-lg-7 col-sm-6">
      <CartSubmit cartDataList={props.cartDataList}/>
      </div>
        <div className="col-lg-5 col-sm-6">
<CartView cartDataList={props.cartDataList} loggedIn={loggedIn}/>
      </div>
      
  </div>
      ) : (
        <div style={{fontSize: '25px', fontWight: 500, paddingTop: '10%', paddingBottom: '10%', paddingLeft: '1%'}}>
          {/* <Link id='attractionContinue' to="/">Continue to shopping</Link> */}
          {/* <Link id='attractionContinue' to="/">Download Ticket</Link>
          <Link id='attractionContinue' to="/">Download Invoice</Link> */}
        </div>
      )}
      <Footer />
      <Modal
        open={popUp}
        // onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h3 id="parent-modal-title">{paymentStatus && paymentStatus.statusCode === 'OK' ? 'Booking Successful' : 'Something Went Wrong'}</h3>
          <p id="parent-modal-description">
            Your Transaction Id : <div style={{fontWeight: '600'}}>{transId.TransID}</div>
          </p>
          <p id="parent-modal-description">
            Your CompanyRef     : <div style={{fontWeight: '600'}}>{transId.CompanyRef}</div>
          </p>
          <Link id='attractionContinue' to="/">Continue to shopping</Link><br/>

          {/* <Link id='attractionContinue' onClick={ticketOpen}>Download Ticket</Link> <br/> */}
          {/* <Link id='attractionContinue' onClick={ticketOpen}>Download Invoice</Link> */}
          <Button className='btn-yellow' 
          // startIcon={<AddShoppingCartIcon/>}
          variant="contained" onClick={(()=>ticketOpen(0))}>Download Ticket</Button>
           <Button className='btn-yellow' style={{marginTop: '2%'}}
          // startIcon={<AddShoppingCartIcon/>}
          variant="contained" onClick={()=>ticketOpen(1)}>Download Invoice</Button>
        </Box>
      </Modal>
    </>
  );
};
const mapStateToProps = state => {
  const cartDataList = state && state.cartReducers && state.cartReducers.cartData ? 
  [...state.cartReducers.cartData]: []
  console.log("state::::", state)
  console.log("cartDataList::::", cartDataList)
  return {
    cartDataList: cartDataList
  }
}
export default connect(mapStateToProps)(ShowCart)

