import CommonBanner from "./global-components/common_banner";
import Footer from "./global-components/footer";
import Subscribe from "./global-components/subscribe";
import TopNav from "./global-components/top_nav";

const Privecypolicy = () => {
  return (
    <>
      <TopNav />
      <CommonBanner title="Privacy Policy" />
      <div className="container">
        <div className="tour-details-wrap">
          <h4 className="single-page-small-title">Privacy Policy</h4>
          <p>
            At Hunjra Tourism & Travels, we respect our valued customer's privacy
            and ensure not to collect any information other than the information
            relevant to make booking with us. As such, we strive to take care of
            our customer’s right to privacy in connection with their interaction
            with this website. Our scope of commitment as part of the use of
            your information is outlined herein.
          </p>
          <ul>
            <li>
              <h4 className="single-page-small-title">
                {" "}
                Collection of Personal Information{" "}
              </h4>
            </li>
            <p>
              We collect your information mainly when you contact us to inquire
              us about our products and services or make a booking with us. This
              usually includes your name , contact details,email id,physical
              address,credit card or payment details,travel requisites and
              referral source. Upon the submission of information, you give
              consent to Hunjra Tourism to use your information to process orders
              in an accurate and prompt manner.
            </p>

            <br />
            <li>
              <h4 className="single-page-small-title">Use of Information</h4>
              <p>
                Any personal information of a client obtained through this
                website will be utilized by Hunjra Tourism to process his or her
                booking, verify credit card details, and provide relevant
                information associated with client’s travel or any other
                subsidiary services he or she would like to avail of. This
                information will also be used for auditing, research and
                activities focused to improve the performance of our website.
              </p>
            </li>
            <li>
              <h4 className="single-page-small-title">
                {" "}
                Privacy of Your Information
              </h4>
              <p>
                All information provided by our customers during online booking,
                such as their name,address,email id and credit card details is
                considered private, and will not be disclosed or sold to anyone
                except for certain suppliers or third parties whose involvement
                in the loop is fundamental for the successful processing of your
                order. But before disclosing your information, we make sure that
                these third parties abide by our privacy policy and adhere to
                strict safety measures.
              </p>
            </li>
            <br />
            <li>
              <h4 className="single-page-small-title">
                {" "}
                Legal Disclosure of Information
              </h4>
              <p>
                We may disclose your information, if we feel that such
                disclosure is pertinent to protect our company’s rights and / or
                abide by a court order or a legal proceeding.
              </p>
            </li>
            <br />
            <li>
              <h4 className="single-page-small-title">
                Non-Personal Information
              </h4>
              <p>
                Hunjra Tourism consistently collects data from website, with the
                help of patterns via web logs and third party service providers.
                But this data is mostly deployed to evaluate the efficiency of
                our websites’ content and features.
              </p>
            </li>
            <br />
            <li>
              <h4 className="single-page-small-title"> Opt-Out</h4>
              <p>
                We provide options for visitors to our website to ‘opt-out’ of
                having their personal information used for certain purposes. For
                instance, if you don’t want to receive any marketing material by
                way of newsletter or promotional emails, you can choose to
                request us not to send advertising information from Hunjra Tourism
                or our affiliate websites.
              </p>
            </li>
            <br />
            <li>
              <h4 className="single-page-small-title">Contests and Surveys</h4>
              <p>
                Hunjra Tourism conducts contests, drawings and surveys every now
                and then. Some contents are organized in collaboration with a
                third party sponsor, and visitors to our websites will be
                informed at the time of the contest regarding the involvement of
                a particular third party and their extent of using your personal
                information. Since participating in these contests is voluntary,
                it’s solely at your discretion whether or not to partake in them
                and reveal your personal information
              </p>
            </li>
            <br />
            <li>
              <h4 className="single-page-small-title">Secured Transaction</h4>
              <p>
                In order to maintain accuracy of data and avoid unauthorized
                access of our client’s personal information, we make sure that
                all transactions are carried out through our secured server.
                Moreover, we utilize technical safeguard system such as
                encryption, socket layers, and firewalls to secure your
                sensitive information like credit card details.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <Subscribe />
      <Footer />
    </>
  );
};

export default Privecypolicy;
