import React, { Component } from "react";
import AboutUs from "../pages/aboute_us";
import Attractions from "../pages/attractions";
import Combo from "../pages/combo";
import Contactpage from "../pages/contact_page";
import ShowCart from "../pages/addCart/ShowCart";
import DatedTktPayment from "../pages/DatedTktPayment";
import Expopage from "../pages/Expo/expo-page";
import ApiTicketConfirmation from "../pages/global-components/ApiTicketConfirmation";

import Billdesign from "../pages/global-components/billdesignpage";

import PaymentPageNew from "../pages/global-components/PaymentPageNew";

import Home from "../pages/home";

import Loginpage from "../pages/Loginpage";

import Paymentconfirmation from "../pages/PaymentConfirmation";
import Privecypolicy from "../pages/Privecypolicy";
import Termsandconditions from "../pages/Termsandcondtions";

import TourPackagesDetail from "../pages/tourpackages_detail";
import TourPackDetail from "../pages/tourpack_detail";
import ComboTourPackDetail from "../pages/combo_tourpack_detail";

import TourpackdayPackList from "../pages/tour_pack_day_detail";
import TourdayPackList from "../pages/tour_pack_day_detail";
import Userprofile from "../pages/use-profile/Userprofile";
import VisaBooingForm from "../pages/visaservises/VisaBookingForm";

import Visapage from "../pages/visa_page";
import ShowBlog from "../blog/ShowBlog";
import SinglePost from "../blog/SinglePost";
const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
  },
  {
    path: "/about-us",
    component: AboutUs,
  },
  {
    path: "/blog",
    component: ShowBlog,
  },
  {
    path: "/single-post/:id",
    component: SinglePost,
  },
  {
    path: "/attraction",
    component: Attractions,
  },
  {
    path: "/combo",
    component: Combo,
  },

  // {
  //     path: "/pakages/holiday",
  //     component: Holidaypack,

  // },
  // {
  //     path: "/pakages/honeymoon",
  //     component: Honeymoonpack,

  // },
  {
    path: "/tour-packages",
    component: TourpackdayPackList,
  },
  {
    path: "/contact-page",
    component: Contactpage,
  },
  {
    path: "/show-cart",
    component: ShowCart,
  },  
  {
    path: "/tour-list",
    component: TourdayPackList,
  },
  {
    path: "/dated-ticket-payment",
    component: DatedTktPayment,
  },
  {
    path: "/tour-pack-details/:id",
    component: TourPackDetail,
  },
  {
    path: "/combo-tour-pack-details/:id",
    component: ComboTourPackDetail,
  },
  {
    path: "/tour-day-pack-details/:id",
    component: TourPackagesDetail,
  },
  {
    path: "/login-page",
    component: Loginpage,
  },
  {
    path: "/online-payment-port",
    component: PaymentPageNew,
  },

  {
    path: "/user-profile-page",
    component: Userprofile,
  },
  {
    path: "/api-tkt-confirmation-page",
    component: ApiTicketConfirmation,
  },
  // {
  //   path: "/user-otp-veryfy-page",
  //   component: OtpPage,
  // },
  // {
  //   path: "/Forget-Password-page",
  //   component: Forgetpass,
  // },
  // {
  //   path: "/change-password",
  //   component: Passwordreset,
  // },
  {
    path: "/dubai-expo-2020",
    component: Expopage,
  },
  {
    path: "/visa-services",
    component: Visapage,
  },
  {
    path: "/payment-confirmation-page",
    component: Paymentconfirmation,
  },
  {
    path: "/billdesign",
    component: Billdesign,
  },
  {
    path: "/terms-and-contitions",
    component: Termsandconditions,
  },
  {
    path: "/Privecy-policy",
    component: Privecypolicy,
  },
  {
    path: "/visa-booking-service",
    component: VisaBooingForm,
  },
];
export default routes;
