import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CartIcon from "../../commonComponents/CartIcon";
import RouteMenu from "../../routes/route-menu";
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import encryptStorage from "../../utils/Encryptstorage";
import {IMAGES_PATH_URL} from "../../utils/constants";
const TopNav = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imgattr = "logo";
  let anchor = "#";
  const [LoggedIn, setLoggedIn] = useState(0);
  const [CusName, setCusName] = useState("Guest");
  const [profilePhoto, setProfilePhoto] = useState("");

  const [userType, setuserType] = useState();
  useEffect(() => {
    let isLoggedIn = sessionStorage.getItem("isLoggedIn");

    if (isLoggedIn == "yes") {
      setLoggedIn(1);
      let userName = sessionStorage.getItem("userName");
      setCusName(userName);
    }

    let login = sessionStorage.getItem("isLoggedIn");
    if (login == "yes") {
      const enl = encryptStorage.getItem("enl"); //obj //agency.agencyId
      let urlObject = JSON.parse(enl);
      let tempObj = urlObject;
      // setcusObj(tempObj);

      console.log("cusObj:::", urlObject);
      if (urlObject.b2b == true) {
        // let agency = urlObject.agency.agencyId; //agencyName
        setProfilePhoto(urlObject.agency.profilePhoto)
      } else if (urlObject.b2bUser == true) {
        // let agencyuser = urlObject.agencyUser.agencyUserId;
        setProfilePhoto(urlObject.agencyUser.profilePhoto)
      } else if (urlObject.b2c == true) {
        // let agencyuser = urlObject.customer.customerId;
        setProfilePhoto(urlObject.customer.profilePhoto)
      }
    }
  }, [setLoggedIn, setCusName]);
  const logout = (e) => {
    sessionStorage.clear();
    window.location.reload();
    console.log("clear");
    window.location.href = "/";
  };
  return (
    <>
      <nav className="navbar navbar-area navbar-expand-lg nav-style-01 viaje-go-top">
        <div className="container nav-container">
          <div className="responsive-mobile-menu">
            <div className="mobile-logo">
              <Link to="/">
                <img
                  src={publicUrl + "assets/img/company_logo.png"}
                  height="100px"
                  width="200px"
                  alt={imgattr}
                />
              </Link>{" "}
              &nbsp;&nbsp;
              {LoggedIn == 1 ? (
                <>
                <Button 
                       onClick={(e) => logout(e)}
                      endIcon={<LogoutIcon />}
                      className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium  css-sghohy-MuiButtonBase-root-MuiButton-root">
                      Logout
                      </Button>
                  &nbsp;&nbsp;
                  <div className="userProfileIcon">
                  <Link to="/user-profile-page">
                    {!profilePhoto ?
                    <img
                      src={publicUrl + "assets/img/icons/user_mobile20x20.png"}
                      width="30px"
                    />
                    :
                    <img
                    src={IMAGES_PATH_URL + "profile/"+ profilePhoto}
                    style={{marginTop: '0vh'}}
                    width="30px"
                  />
}
                  </Link>
                  </div>
                </>
              ) : (
                <>
                  <Link to="/login-page">
                  <Button 
                      endIcon={<LoginIcon />}
                      className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium  css-sghohy-MuiButtonBase-root-MuiButton-root">
                      Login
                      </Button>
                    {/* <img
                      src={publicUrl + "assets/img/login.png"}
                      height="25px"
                      width="50px"
                      alt={imgattr}
                    /> */}
                  </Link>
                </>
              )}
            </div>

            <button
              className="navbar-toggler float-right"
              type="button"
              data-toggle="collapse"
              data-target="#tp_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggle-icon">
                <span className="line" />
                <span className="line" />
                <span className="line" />
              </span>
            </button>
            <div className="nav-right-content">
              <ul className="pl-0">
                <li className="top-bar-btn-booking1">
                <Link to="/show-cart">
              <CartIcon/>
              </Link>
                  {/* {LoggedIn == 1 ? (
                    <>
                     <Button 
                       onClick={(e) => logout(e)}
                      endIcon={<LogoutIcon />}
                      className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium  css-sghohy-MuiButtonBase-root-MuiButton-root">
                      Logout
                      </Button>
                    </>
                  ) : (
                    <>
                      <Link to="/login-page">
                      <Button 
                      endIcon={<LoginIcon />}
                      className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium  css-sghohy-MuiButtonBase-root-MuiButton-root">
                      Login
                      </Button>
                      </Link>
                    </>
                  )} */}
                </li>
              </ul>
            </div>
          </div>

          {/* navigation menu */}

          <div className="collapse navbar-collapse" id="tp_main_menu">
            <div className="logo-wrapper desktop-logo" style={{marginLeft: '0%'}}>
              <Link to="/" className="main-logo">
                <img
                  src={publicUrl + "assets/img/company_logo.png"}
                  height="100px"
                  width="200px"
                  alt="logo"
                />
              </Link>
              <Link to="/" className="sticky-logo">
                <img
                  src={publicUrl + "assets/img/company_logo.png"}
                  height="100px"
                  width="200px"
                  alt="logo"
                />
              </Link>
            </div>
            <ul className="navbar-nav" style={{marginLeft: '1%'}}>
              {RouteMenu.map((menu, index) =>
                menu.fixMenu ? (
                  <React.Fragment key={index}>
                    <li>
                      <Link to={menu.path}>{menu.name}</Link>
                    </li>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={index}>
                    <li className="menu-item-has-children">
                      <Link to="#">{menu.name}</Link>
                      <ul className="sub-menu">
                        {menu.SubMemu.map((smenu, sindex) => (
                          <li key={sindex}>
                            <Link to={smenu.path}>{smenu.name}</Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </React.Fragment>
                )
              )}
            </ul>
          </div>
          <div className="nav-right-content">
            <ul>
              {/* <li>
                <img
                  src={publicUrl + "assets/img/expobtn.png"}
                  height="60px"
                  width="140px"
                  alt={imgattr}
                />
              </li> */}
{/* {LoggedIn == 0 && (
                <>
                <li>
                <Link to="/show-cart">
              <CartIcon/>
              </Link>
              </li>
                </>)
} */}
              {LoggedIn == 1 ? (
                <>
                <li>
                <Link to="/show-cart">
              <CartIcon/>
              </Link>
              </li>
                  <li>
                  <Button 
                       onClick={(e) => logout(e)}
                      endIcon={<LogoutIcon />}
                      className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium  css-sghohy-MuiButtonBase-root-MuiButton-root">
                      Logout
                      </Button>
                      <li className="top-bar-btn-booking">
                      <Link to="/user-profile-page">
                        {/* <img
                          src={publicUrl + "assets/img/icons/user.png"}
                          height="25px"
                          width="25px"
                        /> */}

                    {!profilePhoto ?
                    <img
                      src={publicUrl + "assets/img/icons/user.png"}
                      height="25px"
                      width="25px"
                    />
                    :
                    <img
                    src={IMAGES_PATH_URL + "profile/"+ profilePhoto}
                    style={{marginTop: '0vh'}}
                    height="25px"
                    width="25px"
                  />
}
                      </Link>
                    </li>
                  </li>
                </>
              ) : (
                <>
                <li>
                <Link to="/show-cart">
              <CartIcon/>
              </Link>
              </li>
                  <li>
                    <Link to="/login-page">
                      <Button 
                      endIcon={<LoginIcon />}
                      className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium  css-sghohy-MuiButtonBase-root-MuiButton-root">
                      Login
                      </Button>
                    {/* <Button
                    variant="contained"
                        // type="submit"
                        class="btn btn-yellow"
                        endIcon={<LoginIcon />}
                      >
                        Login1 */}
                      {/* </Button> */}
                      {/* <img
                        src={publicUrl + "assets/img/login.png"}
                        height="35px"
                        width="80px"
                        alt={imgattr}
                      /> */}
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default TopNav;
