import { Form, reduxForm, Field } from "redux-form";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";
import RenderField from "../form-components/RenderField";

import { useEffect, useState } from "react";
import axios from "axios";
import requests from "../utils/Requests";

const validate = (values) => {
  const errors = {};
  if (!values.NewPass) {
    errors.NewPass = "Enter Password";
  }
  if (!values.ConfirmPass || values.ConfirmPass != values.NewPass) {
    errors.NewPass = "Password MissMatch";
  }

  return errors;
};

const PassWordResetForm = (props) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  let publicUrl = process.env.PUBLIC_URL + "/";

  useEffect(() => {}, []);
  const [alertTime, setalertTime] = useState(0);
  const passWordReset = (values) => {
    //await // simulate server latency

    let eMail = sessionStorage.getItem("eMail");
    console.log("email", eMail);
    const userData = {
      userName: eMail,
      b2c: true,
      b2b: false,
      b2bUser: false,
      password: values.NewPass,
    };

    console.log(userData);
    axios
      .post(requests.updatePasswordForUser, userData)
      .then((res) => {
        console.log(res.data);
        setalertTime(1);
        setTimeout(function () {
          //Put All Your Code Here, Which You Want To Execute After Some Delay Time.
          // Alert.alert("Alert Shows After 5 Seconds of Delay.")

          setalertTime(0);
          props.resetPass(1);
        }, 5000);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {alertTime == 1 ? (
        <>
          <Alert color="primary">
            Password Reset, Login with your new password
          </Alert>
        </>
      ) : null}
      <Form onSubmit={handleSubmit(passWordReset.bind(this))}>
        <Field
          name="NewPass"
          type="password"
          label="Enter New Password"
          component={RenderField.RenderTextField}
        />

        <Field
          name="ConfirmPass"
          type="password"
          label="Confirm PassWord"
          component={RenderField.RenderTextField}
        />

        <br />
        <div class="d-flex justify-content-center">
          <button type="submit" class="btn btn-yellow" disabled={submitting}>
            Reset Password{" "}
          </button>
        </div>
      </Form>
    </>
  );
};

export default reduxForm({
  form: "PassWordResetForm",

  validate,
})(PassWordResetForm);
