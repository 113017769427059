import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerImage from "./home/banner";
import Intro from "./home/intro";
import OfferAndDiscount from "./home/offer_discount";
import TopAttractions from "./home/top_attractions";
import TopDestination from "./home/top_destination";
import UpcommingPack from "./home/upcomming_tour";
import axios from "axios";
import requests from "../utils/Requests";
import TopNav from "./global-components/top_nav";
import Subscribe from "./global-components/subscribe";
import Footer from "./global-components/footer";
import { Row, Col } from "reactstrap";

// const OfferAndDiscount = lazy(() => import("./home/offer_discount"));
// const TopAttractions = lazy(() => import("./home/top_attractions"));
// const TopDestination = lazy(() => import("./home/top_destination"));
// const UpcommingPack = lazy(() => import("./home/upcomming_tour"));

let publicUrl = process.env.PUBLIC_URL + "/";

const Home = (props) => {
  let agencyuser = 0;
  let agency = 0;
  const [LoggedIn, setLoggedIn] = useState();
  useEffect(() => {
    window.scroll(0, 0);
    let b2b = sessionStorage.getItem("b2b");
    let b2bUser = sessionStorage.getItem("b2bUser");

    if (b2b == "true") {
      agency = sessionStorage.getItem("agencyId");

      getAllTourList(agency, 0);
      setLoggedIn(1);
    } else if (b2bUser == "true") {
      agencyuser = sessionStorage.getItem("agencyUserId");
      getAllTourList(0, agencyuser);
      setLoggedIn(1);
    } else {
      getAllTourList(0, 0);
      setLoggedIn(0);
    }
  }, []);
  let topDes = [];
  let offDis = [];
  let topAtt = [];
  let tourPack = [];
  let upComPack = [];
  const [Testvariable, setTestvariable] = useState(1);

  const [topDestList, settopDestList] = useState();
  const [topAttList, settopAttList] = useState();
  const [offDisList, setoffDisList] = useState();
  const [tourPackList, settourPackList] = useState();
  const [upCommingList, setupCommingList] = useState();
  const [allTourCommon, setallTourCommon] = useState([]);
  const [allTourFilter, setallTourFilter] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const getAllTourList = async (b2bid, b2buserid) => {
    await axios
      .post(
        requests.getAttractionAll,
        {
          attractionId: 1,
          agencyId: b2bid,
          agencyUserId: b2buserid,
        }

        // {
        //   httpsAgent: new https.Agent({
        //     rejectUnauthorized: false
        //   })
        // }
      )
      .then((res) => {
        //  console.log(res.data);
        let homeData = res.data;
        setallTourCommon(res.data);
        for (let i = 0; i < res.data.length; i++) {
          //   console.log(homeData[i].attName);
          // eslint-disable-next-line no-lone-blocks

          if (homeData[i].attOffersAndDiscount) {
            offDis.push(homeData[i]);
          }
          if (homeData[i].attTopAttractions) {
            topAtt.push(homeData[i]);
          }
          if (homeData[i].attTopDestination) {
            topDes.push(homeData[i]);
          }
          if (homeData[i].attUpComingTours) {
            upComPack.push(homeData[i]);
          }
        }

        settopAttList(topAtt);
        settopDestList(topDes);
        setoffDisList(offDis);
        setupCommingList(upComPack);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // if (isLoading) {
  //   dispatch(actone())
  // }\\

  const [search, setsearch] = useState("");
  const fulterAttraction = allTourCommon.filter((attract) => {
    return attract.attName.toLowerCase().includes(search.toLocaleLowerCase());
  });

  return (
    <>
      <TopNav />
      {/* <br /> */}
      {/* <br /> */}
      {/* <br /> */}
      {/* <br /> */}

      <BannerImage />

      <div className="search-area tp-main-search-area viaje-go-top">
        <div className="container">
          <div className="tp-main-search">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="tp-search-single-wrap">
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Search for destination, activity or attraction"
                    onChange={(e) => setsearch(e.target.value)}
                  />
                  <i className="ti-search" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {search == "" ? null : (
        <>
          {" "}
          <br />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-9">
                <div className="section-title text-center">
                  <h2
                    className="title wow animated fadeInUp"
                    data-wow-duration="0.6s"
                    data-wow-delay="0.1s"
                  >
                    {fulterAttraction == "" ? (
                      <>Not Found</>
                    ) : (
                      <>Search Results</>
                    )}
                  </h2>
                </div>
              </div>
            </div>

            <div className="row">
              {fulterAttraction.map((attraction, index) => (
                <div className="col-lg-3 col-sm-6" key={index}>
                  <div
                    className="single-destinations-list style-two wow animated fadeInUp single-destinations-list text-center"
                    data-wow-duration="0.4s"
                    data-wow-delay="0.1s"
                  >
                    <div className="thumb">
                      <img
                        // src={publicUrl + "assets/img/destination-list/4.png"}
                        src={requests.imgpath + attraction.attThumbnailImage}
                        alt="list"
                      />
                      <div className="d-list-btn-wrap">
                        <div className="d-list-btn viaje-go-top">
                          <Link
                            className="btn btn-yellow"
                            to={`tour-pack-details/${attraction.attractionsId}`}
                          >
                            Book Now <i className="fa fa-paper-plane" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="details">
                      <p className="location">
                        <img
                          src={publicUrl + "assets/img/icons/1.png"}
                          alt="map"
                        />
                        {attraction.attCity}
                      </p>
                      <Link
                        to={`tour-pack-details/${attraction.attractionsId}`}
                      >
                        <h4 className="title">{attraction.attName}</h4>
                      </Link>
                      {/* <p className="content">7 Days Tour on 2 person</p> */}{" "}
                      <div className="tp-price-meta tp-price-meta-cl">
                        {/* <p>Price</p>
                        <h2>
                          {LoggedIn == 1
                            ? attraction.b2bAdultPrice
                            : attraction.b2cAdultPrice}
                          &nbsp;&nbsp;&nbsp; <small>AED</small>
                        </h2> */}

                        <Row>
                          <Col>
                            <p>
                            <div className="priceColor">Adult Price From</div> <h2>{attraction.adultPrice}</h2>
                            </p>{" "}
                            &nbsp;&nbsp;&nbsp;<small>AED</small>
                          </Col>
                          <Col>
                            <p>
                            <div className="priceColor">Child Price From</div>
                              <br />
                              <h2> {attraction.childPrice}</h2>
                            </p>{" "}
                            &nbsp;&nbsp;&nbsp;<small>AED</small>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {isLoading ? (
        <>
          <div className="container">
            <div className="row justify-content-center">
              <h2>Loading please wait...</h2>
            </div>
          </div>
        </>
      ) : (
        <>
        <div style={{marginTop: '-8%'}}>
          <TopAttractions attData={topAttList} login={LoggedIn} />
          <OfferAndDiscount attData={offDisList} login={LoggedIn} />
          <TopDestination attData={topDestList} login={LoggedIn} />
          <UpcommingPack attData={upCommingList} login={LoggedIn} />
          </div>
        </>
      )}

      {/* <Suspense
        fallback={
          <div className="container">
            <div className="row justify-content-center">
              <h2>Loading please wait...</h2>
            </div>
          </div>
        }
      >
        <TopAttractions attData={topAttList} login={LoggedIn} />
        <OfferAndDiscount attData={offDisList} login={LoggedIn} />
        <TopDestination attData={topDestList} login={LoggedIn} />
        <UpcommingPack attData={upCommingList} login={LoggedIn} />
      </Suspense> */}

      <Intro />
      <Subscribe />
      <Footer />
    </>
  );
};

export default Home;
