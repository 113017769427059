import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import requests from "../../utils/Requests";

const TourPackageList = () => {
  useEffect(() => {
    getAllTourList();
  }, []);
  const [tourlist, setTourlist] = useState([]);
  const [getTourList, setgetTourList] = useState({
    attractionId: 1,
  });
  const getAllTourList = async () => {
    await axios
      .post(requests.getTourPackageList, getTourList)
      .then((res) => {
        setTourlist(res.data);

        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  return (
    <>
      <div className="tour-list-area pd-top-120 viaje-go-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-8 order-lg-12">
              {tourlist.map((tour, index) => (
                <div key={index}>
                  <div className="tour-list-area">
                    <div className="single-destinations-list style-three">
                      <div className="thumb">
                        <img
                          src={requests.imgpath + tour.tourLogo}
                          alt="list"
                        />
                      </div>
                      <div className="details">
                        <p className="location">
                          <img
                            src={publicUrl + "assets/img/icons/1.png"}
                            alt="map"
                          />
                          Dubai
                        </p>
                        <h4 className="title">
                          <Link to="/tour-details">{tour.tourName}</Link>
                        </h4>

                        {tour.attDescription ? (
                          <p className="content">
                            {" "}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: tour.attDescription.substring(0, 250),
                              }}
                            />
                          </p>
                        ) : null}

                        <div className="list-price-meta">
                          <ul className="tp-list-meta d-inline-block">
                            <li>
                              <i className="fa fa-clock-o" /> {tour.tourNofDays}{" "}
                              days
                            </li>
                          </ul>
                          <div className="tp-price-meta d-inline-block">
                            <p>Price</p>
                            <h2>
                              {tour.tourPrice}&nbsp; &nbsp; <span>AED</span>
                            </h2>
                          </div>
                          <Link
                            class="btn btn-yellow"
                            to={`tour-day-pack-details/${tour.tourPackageId}`}
                          >
                            Send Enquiry
                            {/* <i class="fa fa-paper-plane"></i> */}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="col-xl-3 col-lg-4 order-lg-1">
              <div className="sidebar-area">
                <ul class="nav nav-tabs tp-tabs style-two">
                  <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#tabs_1">
                      Honey Moon Pack
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tabs_2">
                      Holiday Pack
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TourPackageList;
