import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import {CONTACT_DETAILS1,CONTACT_DETAILS2, CONTACT_DETAILS3,
  CONTACT_NUMBER1, CONTACT_NUMBER2,CONTACT_MAIL, COMPANY_NAME} from "../../utils/constants"
const Footer = () => {
  useEffect(() => {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);
  }, []);
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imgattr = "Footer logo";
  return (
    <>
      <footer
        className="footer-area"
        style={{ backgroundImage: "url(" + publicUrl + "assets/img/bg/2.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-widget widget">
                <div className="about_us_widget">
                  <img
                    src={publicUrl + "assets/img/company_logo.png"}
                    height="100px"
                    width="250px"
                  />

                  <p>
                    Hunjra Tourism comprise of professionals in the tourism
                    industry. It was a big challenge to establish a company but
                    through hard work, dedication and strong determination, we
                    became one of the leading tour operators in the UAE... read
                    more
                  </p>
                  <ul className="social-icon">
                    <li>
                      <a className="facebook" href="/" target="_blank">
                        <i className="fa fa-facebook  " />
                      </a>
                    </li>
                    <li>
                      <a className="twitter" href="/" target="_blank">
                        <i className="fa fa-twitter  " />
                      </a>
                    </li>
                    <li>
                      <a className="pinterest" href="/" target="_blank">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                  </ul>
                  <span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=axIlNFINih1pdn8uQznMLHcT7mt5PwF6X0eaArwSVnCzojYmpTqjoe0KqwGr"></script></span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer-widget widget ">
                <div className="widget-contact">
                  <h4 className="widget-title">Contact us</h4>
                  <p>
                    <i className="fa fa-map-marker" />
                    <span>
                      {/* 203 API BUILDING, TIA sarees Same building - Bur Dubai -
                      Al Souq Al Kabeer -  */}
                      {COMPANY_NAME} <br/>
                      {CONTACT_DETAILS1}<br/>
{CONTACT_DETAILS2}<br/> 
{CONTACT_DETAILS3}
                    </span>
                  </p>
                  <p className="location">
                    <i className="fa fa-envelope-o" />
                    <span>{CONTACT_MAIL}</span>
                  </p>
                  <p className="telephone">
                    <i className="fa fa-phone base-color" />
                    <span>{CONTACT_NUMBER1}</span>
                  </p>
                  <p className="telephone">
                    <i className="fa fa-mobile base-color" />
                    <span>{CONTACT_NUMBER2}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-widget widget">
                <h4 className="widget-title">Quick Link</h4>
                <ul className="widget_nav_menu  viaje-go-top">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/attraction">Attractions</Link>
                  </li>
                  <li>
                    <Link to="/tcombo">Combo</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  {/* <li>
                    <Link to="/blog">Blog</Link>
                  </li> */}
                  <li>
                    <Link to="/contact-page">Contact</Link>
                  </li>
                  <li>
                    <Link to="/terms-and-contitions">Terms And Contitions</Link>
                  </li>
                  <li>
                    <Link to="/Privecy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6">
              <div className="footer-widget widget">
                <h4 className="widget-title">Instagram Gallery</h4>
                <ul className="widget-instagram-feed">
                  <li>
                    <a href="#">
                      <img
                        src={publicUrl + "assets/img/instagram/1.png"}
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={publicUrl + "assets/img/instagram/2.png"}
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={publicUrl + "assets/img/instagram/3.png"}
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={publicUrl + "assets/img/instagram/4.png"}
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={publicUrl + "assets/img/instagram/5.png"}
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={publicUrl + "assets/img/instagram/6.png"}
                        alt="img"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
        <div className="copyright-inner">
          <div className="copyright-text">
            © Hunjra Tourism 2022 All rights reserved. Powered with by{" "}
            <a href="https://zappylixbytebloom.com/" target="_blank">
              <i className="fa fa-heart" />
              <span>Zappylix Bytebloom</span>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
