const ADD_TO_CART = "ADD_TO_CART";
const REMOVE_TO_CART = "REMOVE_TO_CART";
const addToCartData = (cartData) => ({
    type: ADD_TO_CART,
    cartData: cartData
});
const removeToCartData = (cartData) => ({
    type: REMOVE_TO_CART,
    cartData: cartData
});
export { ADD_TO_CART,REMOVE_TO_CART, addToCartData, removeToCartData };