import TopNav from "./global-components/top_nav";
import LoginForm from "./LoginForm";
import { Row, Col, Card, CardTitle } from "reactstrap";
import { useState } from "react";
import RegisterForm from "./RegisterForm";

import { useDispatch, useSelector } from "react-redux";

import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import OtpVerificationForm from "./OtpVerificationForm";
import EmaiidPassRec from "./EmaiidPassRec";
import PassWordResetForm from "./PassWordResetForm";
//  google login id: 721122259802-5i6smj143h6j0a4nmcktrudr4maah3qs.apps.googleusercontent.com
const Loginpage = () => {
  const googleRes = (responce) => {};
  const responseFacebook = (response) => {};
  const [toggle, setToggle] = useState(true);
  const dispatch = useDispatch();
  const test = useSelector((state) => state.myreducer);

  const [serverResponce, setserverResponce] = useState(1);
  const [userMail, setuserMail] = useState();
  const updatestatus = (val) => {
    setserverResponce(val);
  };
  const userEmail = (val) => {
    setuserMail(val);
  };

  return (
    <>
      <TopNav />
      {/* <CommonBanner    imgUrl ="loginBanner.jpeg"/> */}

      <div className="login-page-bg">
        <br />
        <br />
        <br />
        <br />

        <Row>
          <Col sm={6}></Col>
          <Col sm={4}>
            <Card body className="text-center">
              {serverResponce == 1 ? (
                <>
                  {" "}
                  <CardTitle tag="h5">Login / Register</CardTitle>
                  <LoginForm otppage={updatestatus} email={userEmail} />
                  <hr />
                  <Row>
                    <Col sm={6}>
                      <button
                        type="submit"
                        class="btn btn-yellow"
                        onClick={() => setserverResponce(102)}
                      >
                        Forget Password
                      </button>
                    </Col>
                    <Col sm={6}>
                      <button
                        type="submit"
                        class="btn btn-yellow"
                        onClick={() => setserverResponce(3)}
                      >
                        Register Here
                      </button>
                    </Col>
                  </Row>{" "}
                </>
              ) : serverResponce == 101 ||
                serverResponce == 110 ||
                serverResponce == 120 ? ( // 101 otp verification bending  110 New Regidtration // 120 forget passord
                <>
                  <CardTitle tag="h5">
                    Enter OTP, Has Been Recived Your Email
                  </CardTitle>
                  <OtpVerificationForm
                    userMail={userMail}
                    //  errcode={serverResponce}
                    otpVerify={updatestatus}
                    servercode={serverResponce}
                  />
                  <hr />
                </>
              ) : serverResponce == 102 ? (
                <>
                  {" "}
                  <CardTitle tag="h5">Enter Your Registered Email </CardTitle>
                  <EmaiidPassRec
                    passwordRec={updatestatus}
                    otppage={updatestatus}
                  />
                  <hr />
                  <Row>
                    <Col sm={6}>
                      <button
                        type="submit"
                        class="btn btn-yellow"
                        onClick={() => setserverResponce(1)}
                      >
                        Login
                      </button>
                    </Col>
                    <Col sm={6}>
                      <button
                        type="submit"
                        class="btn btn-yellow"
                        onClick={() => setserverResponce(3)}
                      >
                        Register Here
                      </button>
                    </Col>
                  </Row>{" "}
                </>
              ) : serverResponce == 103 ? (
                <></>
              ) : serverResponce == 3 ? (
                <>
                  {" "}
                  <CardTitle tag="h5">Register Here </CardTitle>
                  <RegisterForm otpVerify={updatestatus} />
                  <hr />
                  <Row>
                    <Col sm={6}>
                      <button
                        type="submit"
                        class="btn btn-yellow"
                        onClick={() => setserverResponce(1)}
                      >
                        Login
                      </button>
                    </Col>
                    <Col sm={6}>
                      <button
                        type="submit"
                        class="btn btn-yellow"
                        onClick={() => setserverResponce(102)}
                      >
                        Forget Password
                      </button>
                    </Col>
                  </Row>
                </>
              ) : serverResponce == 10 ? (
                <>
                  <CardTitle tag="h5">
                    {" "}
                    Enter Your New Password To Reset{" "}
                  </CardTitle>
                  <PassWordResetForm resetPass={updatestatus} />
                </>
              ) : (
                <></>
              )}

              {/* fixed for all component */}
              <div class="strike">
                <span>OR LOGIN USING</span>
              </div>

              <Row>
                <Col sm={6}>
                  <FacebookLogin
                    appId="3127343574203866"
                    // autoLoad={true}
                    fields="name,email,picture"
                    cssClass="btnFacebook"
                    textButton="Facebook"
                    //   icon="fa fa-google-plus"
                    callback={responseFacebook}
                  />
                </Col>
                <Col sm={6}>
                  <GoogleLogin
                  clientId=""
                    // clientId="721122259802-5i6smj143h6j0a4nmcktrudr4maah3qs.apps.googleusercontent.com"
                    render={(renderProps) => (
                      <button
                        onClick={renderProps.onClick}
                        className="btnGoogle"
                      >
                        {" "}
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                        &nbsp;&nbsp; LOGIN
                      </button>
                    )}
                    onSuccess={googleRes}
                    onFailure={googleRes}
                    cookiePolicy={"single_host_origin"}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm={2}></Col>
        </Row>
      </div>
    </>
  );
};

export default Loginpage;
