import { Link } from "react-router-dom";
import requests from "../../utils/Requests";
import {Row, Col} from 'reactstrap';
import StarRating from "../../common/StarRating"
const TopAttractions = ({ attData, login }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <>
      <div
        className="holiday-plan-area tp-holiday-plan-area mg-top-96"
        style={{
          backgroundImage: "url(" + publicUrl + "assets/img/bg/8.png)",
        }}
      >
        <div className="container" style={{paddingTop: '2%'}}>
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-9">
              <div className="section-title text-center">
                <h2
                  className="title wow animated fadeInUp"
                  data-wow-duration="0.6s"
                  data-wow-delay="0.1s"
                >
                  Top Attractions
                </h2>
                <p
                  className="wow animated fadeInUp"
                  data-wow-duration="0.6s"
                  data-wow-delay="0.2s"
                >
                  Leave your stresses behind and come along for the ride. The
                  world is a book and those who do not travel read only one
                  page.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            {attData.map((attraction, index) => (
              <div className="col-lg-3 col-sm-6" key={index}>
                <div
                  className="single-destinations-list style-two wow animated fadeInUp single-destinations-list text-center"
                  data-wow-duration="0.4s"
                  data-wow-delay="0.1s"
              
                >
                  <div className="thumb tour_container">
                  <div class="ribbon_3 popular"><span>Popular</span></div>
                    {/* <img style={{opacity: '0.5'}} */}
                    <img style={{opacity: '1'}}
                      // src={publicUrl + "assets/img/destination-list/4.png"}
                      src={requests.imgpath + attraction.attThumbnailImage}
                      alt="list"
                    />
    
                     <div className="details">
                    <p className="location" style={{color: 'black'}}>
                      <img
                        src={publicUrl + "assets/img/icons/1.png"}
                        alt="map"
                      />
                      {attraction.attCity}
                    </p>
                    
                    <Link to={`tour-pack-details/${attraction.attractionsId}`}>
                      <h4 className="title attNameFont">{attraction.attName}</h4>
                    </Link>
                    <div className="tp-price-meta tp-price-meta-cl">
<Row style={{marginLeft: '0%',}}>
<Col><p><div className="priceColor">Adult Price From</div><h2>{attraction.adultPrice}&nbsp;&nbsp;&nbsp;<small id='attractionAed'>AED</small></h2></p></Col>
                        <Col><p ><div className="priceColor">Child Price From</div><br/><h2>{attraction.childPrice}&nbsp;&nbsp;&nbsp;<small id='attractionAed'>AED</small></h2></p> </Col>
                      </Row>
                      <div style={{marginLeft: '5%'}}>
                      <StarRating />
                      </div>
                    </div>
                  </div>

<div className="d-list-btn-wrap">
                                <div className="d-list-btn viaje-go-top">
                                  <Link
                                    className="btn btn-yellow"
                                    to={`tour-pack-details/${attraction.attractionsId}`}
                                  >
                                    Book Now <i className="fa fa-paper-plane" />
                                  </Link>
                                </div>
                              </div>
                  </div>                 
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TopAttractions;
