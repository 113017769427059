import { reduxForm, Form, Field, reset } from "redux-form";
import RenderField from "../form-components/RenderField";
import {
  Row,
  Col,
  Card,
  Button,
  CardHeader,
  CardBody,
  Label,
  Input,
  Alert,
} from "reactstrap";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import requests from "../utils/Requests";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TopNav from "../pages/global-components/top_nav";
import Subscribe from "../pages/global-components/subscribe";
import Footer from "../pages/global-components/footer";
import DynamicBanner from "../pages/global-components/dynamic_banner";
import Attractionbygroup from "./comboAttractionByGroup";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import encryptStorage from "../utils/Encryptstorage";
import MainListCombo from "../commonComponents/MainListCombo";
import { FILE_STORE_BASE_URL } from "../utils/constants";
const AfterSubmit = (result, dispatch) => {
  // console.log('result::::', result)
  dispatch(reset("TicketBookingForm"));
  //window.location.href = "/success";
};
const validate = (values) => {
  // console.log('validate::::', values)
  const errors = {};
  if (!values.bookCustomerName) {
    errors.bookCustomerName = "Required ";
  }
  if (!values.bookCustomerEmail) {
    errors.bookCustomerEmail = "Required ";
  }
  if (!values.bookMobileNumber) {
    errors.bookMobileNumber = "Required";
  }
  if (!values.passengertktType) {
    errors.passengertktType = "Required";
  }
  if (!values.bookNofAdult) {
    errors.bookNofAdult = "Required ";
  }
  if (!values.bookPaymentMode) {
    errors.bookPaymentMode = "Required";
  }
  if (!values.ticketTypeId) {
    errors.ticketTypeId = "Required";
  }
  if (!values.bookTravellDate) {
    errors.bookTravellDate = "Required ";
  }
  return errors;
};

const ComboPackdetail = (props) => {
  // console.log('props:::', props)
  const { handleSubmit, pristine, reset, submitting } = props;

  let history = useHistory();
  const { id } = useParams();
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [agency, setagency] = useState(0);
  const [agencyuser, setagencyuser] = useState(0);
  const [userId, setuserId] = useState(0);
  let addonget = { attractionId: id };
  const [LoggedIn, setLoggedIn] = useState();
  const [bookingAddon, setbookingAddon] = useState([]);
  const [tourpack, setTourpack] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [adultfare, setadultfare] = useState();
  const [childfare, setchildfare] = useState();
  const [numOfAdult, setnumOfAdult] = useState(0);
  const [numOfChild, setnumOfChild] = useState(0);
  const [addonPrice, setaddonPrice] = useState(0);
  const [b2cUser, setb2cUser] = useState(0);
  const [OpenTicket, setOpenTicket] = useState("");
  const [selfAgentTicket, setSelfAgentTicket] = useState("0");
  const [agentDetails, setAgentDetails] = useState({id:null, agentName: null, creditAmount: null,
    agencyEmail: null, agencyPhoneNumber: null });

  const [userName, setuserName] = useState();
  //for Form Submit

  const [insuffBal, setinsuffBal] = useState(0);
  const [paymentSelection, setpaymentSelection] = useState(0);
  const [showAlert, setshowAlert] = useState(0);
  const [userdata, setuserdata] = useState([]);
  useEffect(() => {
    encryptStorage.removeItem("DFuck");
    encryptStorage.removeItem("url");
    encryptStorage.removeItem("suckapi");
    encryptStorage.removeItem("firstSubmitForBooking");

    // const currentUrl = window.location.href;
    // const pathname = window.location.pathname;
    // console.log("current url", currentUrl);
    // console.log("Path Name", pathname);
    // console.log(pathname.split("/"));
    // console.log(pathname.split("/")[2]);

    window.scrollTo(0, 0);

    props.initialize({ attractionsId: id, bookPaymentMode: 1 });

    let login = sessionStorage.getItem("isLoggedIn");

    if (login == "yes") {
      const enl = encryptStorage.getItem("enl"); //obj //agency.agencyId
      // console.log('enl::::', enl)
      let urlObject = JSON.parse(enl);
      setuserdata(urlObject);
      // console.log('urlObject::::', urlObject)

      if (urlObject.b2b == true) {
        let agency = urlObject.agency.agencyId;

        setagency(agency);
        getTourDeail(agency, 0);
        setLoggedIn(1);
      } else if (urlObject.b2bUser == true) {
        let agencyuser = urlObject.agencyUser.agencyUserId;

        setagencyuser(agencyuser);
        getTourDeail(0, agencyuser);
        setLoggedIn(1);
      } else {
        setuserId(urlObject.customer.customerId);
        setb2cUser(1);
        getTourDeail(0, 0);
        setLoggedIn(0);
        setOpenTicket(2);
        //  props.initialize({});
      }
    } else {
      setOpenTicket(2);
      getTourDeail(0, 0);
      setLoggedIn(0);
    }

    getFormAddons();
    getBannerAddons();
    apicalltkt(id);
    apicallAgent();
  }, []);
  // let attraction = { attractionsId: id,  agencyId: agency , agencyUserId: agencyuser};
  let atID;
  const getTourDeail = async (b2bid, b2buserid) => {
    await axios
      .post(requests.getComboDetails, {
        attractionsId: id,
        agencyId: b2bid,
        agencyUserId: b2buserid,
      })
      .then((res) => {
        setTourpack(res.data);

        // console.log(res.data);

        //attConnectWithApi
        // const [OpenTicket, setOpenTicket] = useState(1);
        if (res.data.attConnectWithApi == true) {
          // setOpenTicket(2);
        }

        setisLoading(false);
        // settktfare(tourpack.price)
        // console.log(res.data);
        if (LoggedIn == 1) {
          setadultfare(res.data.b2bAdultPrice);
          setchildfare(res.data.b2bChildPrice);
        } else {
          setadultfare(res.data.b2cAdultPrice);
          setchildfare(res.data.b2cChildPrice);
        }
        atID = res.data.attGroup;
        getAttAllByGroup(atID);
        sessionStorage.setItem("attname", res.data.attName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [attractionList, setattractionList] = useState([]);
  // let GroupId = { attGroup: tourpack.attGroup };
  const [AttGrouFilter, setAttGrouFilter] = useState([]);
  const getAttAllByGroup = async (gId) => {
    await axios
      .post(requests.getattractionallbygroup, { attGroup: gId })
      .then((res) => {
        setattractionList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [formAddOn, setformAddOn] = useState();
  const [isFormLoading, setisFormLoading] = useState(true);
  const getFormAddons = async () => {
    await axios
      .post(requests.setaddonformlist, addonget)
      .then((res) => {
        setformAddOn(res.data);
        setisFormLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [bannerAddOn, setbannerAddOn] = useState();
  const [isBannerLoading, setisBannerLoading] = useState(true);
  const getBannerAddons = async () => {
    await axios
      .post(requests.getaddonbannerlist, addonget)
      .then((res) => {
        setbannerAddOn(res.data);
        setisBannerLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [tkttypeTemp, settkttypeTemp] = useState([]);
  const [apiTicketType, setApiTicketType] = useState([]);
  const [apiAgentList, setApiAgentList] = useState([]);

  const apicalltkt = (comboId) => {
    axios
      .post(requests.getComboTicketListByComboId, { comboId: comboId })
      .then((res) => {
        // console.log("Ticket Type", res.data);
        const values = [...tkttypeTemp];
        values.length = 0;
        values.push({
          name: "Select Ticket Type",
          value: "",
          // eventtypeId: "",
          // resourceID: "",
        });

        const apiTicket = [...apiTicketType];
        apiTicket.length = 0;
        apiTicket.push({
          name: "Select Ticket Type",
          value: "",
          eventtypeId: "",
          resourceID: "",
        });

        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].resourceID == null || res.data[i].resourceID == 0) {
            values.push({
              name: res.data[i].ttTicketType,
              value: res.data[i].ticketTypeId,
              // eventtypeId: res.data[i].eventtypeId,
              // resourceID: res.data[i].resourceID,
            });
          } else {
            apiTicket.push({
              name: res.data[i].ttTicketType,
              value: res.data[i].ticketTypeId,
              eventtypeId: res.data[i].eventtypeId,
              resourceID: res.data[i].resourceID,
            });
          }
        }
        setApiTicketType(apiTicket);
        // console.log("value", values);
        settkttypeTemp(values);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const apicallAgent = () => {
    axios
      .post(requests.getagencylist, {})
      .then((res) => {
        // console.log("Ticket Type", res.data);
        const values = [];
        values.length = 0;
        values.push({
          name: "Select Agent",
          value: "",
        });
        for (let i = 0; i < res.data.length; i++) {
            values.push({
              name: res.data[i].agencyName,
              value: res.data[i].agencyId,
              currentBalance: res.data[i].currentBalance,
              agencyId: res.data[i].agencyId,
              agencyEmail: res.data[i].agencyEmail,
              agencyPhoneNumber: res.data[i].agencyPhoneNumber
            });
        }
        setApiAgentList(values);
        // console.log("value::::", values);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkboxvalues = (e) => {
    let val = e.target.id;

    let newprice = e.target.value;

    let tempAddon = [...bookingAddon];
    if (e.target.checked == true) {
      let finalprice = Number(addonPrice) + Number(newprice);
      setaddonPrice(finalprice);
      let tempEle = { addonId: val };
      tempAddon.push(tempEle);
      setbookingAddon(tempAddon);
    } else {
      let finalprice = Number(addonPrice) - Number(newprice);
      setaddonPrice(finalprice);
      let temp = bookingAddon.filter((item) => item.addonId != val);
      setbookingAddon(temp);
    }
  };

  const [tktAvailability, settktAvailability] = useState(0);
  const [servserRes, setservserRes] = useState();
  const [showTktFare, setshowTktFare] = useState(0);
  const [actAdultfare, setactAdultfare] = useState();
  const [actChildfare, setactChildfare] = useState();
  const [setEventIdErr, setsetEventIdErr] = useState(false);
  const [eveId, seteveId] = useState("");
  const [resId, setresId] = useState("");
  const getAgentDetails = val => {
    let agency = apiAgentList.filter((item) => item.value == val);
    // console.log('getAgentDetails agency:::', agency)
    if(agency.length == 1){
      props.initialize(...{['bookMobileNumber']: agency[0].agencyPhoneNumber, ['bookCustomerEmail']: agency[0].agencyEmail });
    setAgentDetails({agencyId: agency[0].agencyId, agentName: agency[0].agentName, currentBalance: agency[0].currentBalance,
      agencyEmail: agency[0].agencyEmail, agencyPhoneNumber:agency[0].agencyPhoneNumber })
    }
  // console.log('getAgentDetails:::', val)
  // console.log('getAgentDetails:::', agentDetails)
  }
  const gettktDisPrice = (val) => {
    // console.log(tourpack.attConnectWithApi);

    let newDate = new Date();
    // console.log(newDate);
    //Open Ticket(1) --local(old Methed) Dated ticket(2) -- from Api(New Methed)

    // console.log("Ticket Price 111111111111");

    if (tourpack.attConnectWithApi == true) {
      if (OpenTicket == 2) {
        let temp = apiTicketType.filter((item) => item.value == val);
        // console.log("sdsdsdsdsdsdsdsd", temp); //eventtypeId, resourceID
        seteveId(temp[0].eventtypeId);
        setresId(temp[0].resourceID);
        setsetEventIdErr(false);
      } else {
        axios
          .post(requests.getAttractionTicketTypePrice, {
            ticketTypeId: val,
            agencyId: agency,
            agencyUserId: agencyuser,
            attractionsId: id,
          })
          .then((res) => {
            // errorCode 505 No Adult Ticket
            // errorCode 504 No Child Ticket
            // console.log("Ticket Price", res.data);

            if (LoggedIn == 1) {
              // for b2b

              // console.log("")
              if (res.data.errorCode == 505) {
                setshowTktFare(1);
                setservserRes("Ticket Not Available");
                settktAvailability(1);
              } else if (res.data.errorCode == 504) {
                if (res.data.b2bAdultDisPrice == 0) {
                  setadultfare(res.data.b2bAdultPrice); //b2bChildDisPrice
                  setchildfare(0);
                  setshowTktFare(1);
                  setservserRes("Child Ticket Not Available");
                  settktAvailability(1);
                } else {
                  setadultfare(res.data.b2bAdultDisPrice);
                  setchildfare(0);
                  setshowTktFare(1);
                  setservserRes("Child Ticket Not Available");
                  settktAvailability(1);
                }
              } else {
                if (res.data.b2bAdultDisPrice == 0) {
                  setadultfare(res.data.b2bAdultPrice); //b2bChildDisPrice
                  setchildfare(res.data.b2bChildPrice);
                  setshowTktFare(1);
                  settktAvailability(0);
                } else {
                  setadultfare(res.data.b2bAdultDisPrice); //b2bChildDisPrice
                  setchildfare(res.data.b2bChildDisPrice);
                  setshowTktFare(1);
                  settktAvailability(0);
                }
              }
            } else {
              //for b2c

              if (res.data.errorCode == 505) {
                setshowTktFare(0);
                setservserRes("Ticket Not Available");
                settktAvailability(1);
              } else if (res.data.errorCode == 504) {
                setadultfare(res.data.b2cAdultPrice); //b2bChildDisPrice
                setchildfare(0);
                setshowTktFare(1);
                setservserRes("Child Ticket Not Available");
                settktAvailability(1);
              } else {
                setadultfare(res.data.b2cAdultPrice);
                setchildfare(res.data.b2cChildPrice);
                setshowTktFare(1);
                settktAvailability(0);
              }
            }
          })
          .catch((err) => {});
      }
    } else {
      axios
        .post(requests.getAttractionTicketTypePrice, {
          ticketTypeId: val,
          agencyId: agency,
          agencyUserId: agencyuser,
          attractionsId: id,
        })
        .then((res) => {
          // errorCode 505 No Adult Ticket
          // errorCode 504 No Child Ticket
          // console.log("Ticket Price", res.data);

          if (LoggedIn == 1) {
            // for b2b

            // console.log("")
            if (res.data.errorCode == 505) {
              setshowTktFare(1);
              setservserRes("Ticket Not Available");
              settktAvailability(1);
            } else if (res.data.errorCode == 504) {
              if (res.data.b2bAdultDisPrice == 0) {
                setadultfare(res.data.b2bAdultPrice); //b2bChildDisPrice
                setchildfare(0);
                setshowTktFare(1);
                setservserRes("Child Ticket Not Available");
                settktAvailability(1);
              } else {
                setadultfare(res.data.b2bAdultDisPrice);
                setchildfare(0);
                setshowTktFare(1);
                setservserRes("Child Ticket Not Available");
                settktAvailability(1);
              }
            } else {
              if (res.data.b2bAdultDisPrice == 0) {
                setadultfare(res.data.b2bAdultPrice); //b2bChildDisPrice
                setchildfare(res.data.b2bChildPrice);
                setshowTktFare(1);
                settktAvailability(0);
              } else {
                setadultfare(res.data.b2bAdultDisPrice); //b2bChildDisPrice
                setchildfare(res.data.b2bChildDisPrice);
                setshowTktFare(1);
                settktAvailability(0);
              }
            }
          } else {
            //for b2c

            if (res.data.errorCode == 505) {
              setshowTktFare(0);
              setservserRes("Ticket Not Available");
              settktAvailability(1);
            } else if (res.data.errorCode == 504) {
              setadultfare(res.data.b2cAdultPrice); //b2bChildDisPrice
              setchildfare(0);
              setshowTktFare(1);
              setservserRes("Child Ticket Not Available");
              settktAvailability(1);
            } else {
              setadultfare(res.data.b2cAdultPrice);
              setchildfare(res.data.b2cChildPrice);
              setshowTktFare(1);
              settktAvailability(0);
            }
          }
        })
        .catch((err) => {});
    }

    if (OpenTicket == 1) {
      // let temp = apiTicketType.filter((item) => item.value == val);
      // // console.log("sdsdsdsdsdsdsdsd", temp); //eventtypeId, resourceID
      // seteveId(temp[0].eventtypeId);
      // setresId(temp[0].resourceID);
      // setsetEventIdErr(false);
    } else {
      // axios
      //   .post(requests.getAttractionTicketTypePrice, {
      //     ticketTypeId: val,
      //     agencyId: agency,
      //     agencyUserId: agencyuser,
      //     attractionsId: id,
      //   })
      //   .then((res) => {
      //     // errorCode 505 No Adult Ticket
      //     // errorCode 504 No Child Ticket
      //     // console.log(res.data);
      //     if (LoggedIn == 1) {
      //       // for b2b
      //       if (res.data.errorCode == 505) {
      //         setshowTktFare(1);
      //         setservserRes("Ticket Not Available");
      //         settktAvailability(1);
      //       } else if (res.data.errorCode == 504) {
      //         if (res.data.b2bAdultDisPrice == 0) {
      //           setadultfare(res.data.b2bAdultPrice); //b2bChildDisPrice
      //           setchildfare(0);
      //           setshowTktFare(1);
      //           setservserRes("Child Ticket Not Available");
      //           settktAvailability(1);
      //         } else {
      //           setadultfare(res.data.b2bAdultDisPrice);
      //           setchildfare(0);
      //           setshowTktFare(1);
      //           setservserRes("Child Ticket Not Available");
      //           settktAvailability(1);
      //         }
      //       } else {
      //         if (res.data.b2bAdultDisPrice == 0) {
      //           setadultfare(res.data.b2bAdultPrice); //b2bChildDisPrice
      //           setchildfare(res.data.b2bChildPrice);
      //           setshowTktFare(1);
      //           settktAvailability(0);
      //         } else {
      //           setadultfare(res.data.b2bAdultDisPrice); //b2bChildDisPrice
      //           setchildfare(res.data.b2bChildDisPrice);
      //           setshowTktFare(1);
      //           settktAvailability(0);
      //         }
      //       }
      //     } else {
      //       //for b2c
      //       if (res.data.errorCode == 505) {
      //         setshowTktFare(0);
      //         setservserRes("Ticket Not Available");
      //         settktAvailability(1);
      //       } else if (res.data.errorCode == 504) {
      //         setadultfare(res.data.b2cAdultPrice); //b2bChildDisPrice
      //         setchildfare(0);
      //         setshowTktFare(1);
      //         setservserRes("Child Ticket Not Available");
      //         settktAvailability(1);
      //       } else {
      //         setadultfare(res.data.b2cAdultPrice);
      //         setchildfare(res.data.b2cChildPrice);
      //         setshowTktFare(1);
      //         settktAvailability(0);
      //       }
      //     }
      //   })
      //   .catch((err) => {});
    }
  };
  // Ticket timeslot

  const [timeSlot, settimeSlot] = useState();
  const [slot, setslot] = useState(false);
  const [loaderForSlot, setloaderForSlot] = useState(false);

  const checkTimeSlot = (dateVal) => {
    // console.log("Time Slot");
    if (tourpack.attConnectWithApi == true) {
      // console.log("APi True");
      if (OpenTicket == 2) {
        // console.log("Open Ticket");

        // console.log(dateVal);
        if (eveId != "") {
          setloaderForSlot(true);
          setshowTktFare(0);
          setslot(false);
          setsetEventIdErr(false);
          //  console.log(dateVal + eveId + resId);
          //  console.log("eveId", eveId);
          axios
            .post(requests.getBurjTimeSlotWithRates, {
              bookingDate: dateVal,
              eventTypeId: eveId,
              resourceId: resId,
            })
            .then((response) => {
              // console.log(response.data.agentServiceEventsPrice);
              console.log("Time Slot", response.data);
              settimeSlot(response.data.agentServiceEventsPrice);
              setloaderForSlot(false);
              setslot(true);
            })
            .catch(() => {
              setslot(false);
            });
        } else {
          setsetEventIdErr(true);
        }
      }
    }
  };
  const [bookTimeSlot, setbookTimeSlot] = useState("");
  const [selectTimeSlotRes, setselectTimeSlotRes] = useState([]);

  const [agentServiceTicketTypes, setagentServiceTicketTypes] = useState([]);
  const selectedTimeSlot = (
    eventID,
    eventName,
    startDateTime,
    endDateTime,
    eventTypeID,
    resourceID,
    available,
    status
  ) => {
    //getBurjTicketTypeWithRate
    setadultfare(""); //b2bChildDisPrice
    setchildfare("");

    const getRate = {
      agencyId: agency,
      agencyUserId: agencyuser,
      bookB2cId: userId,
      available: available,
      endDateTime: endDateTime,
      eventId: eventID,
      eventName: eventName,
      eventTypeId: eventTypeID,
      resourceId: resourceID,
      startDateTime: startDateTime,
      status: status,
    };

    //console.log("sumit Data", getRate);

    axios
      .post(requests.getBurjTicketTypeWithRates, getRate)
      .then((response) => {
        //  console.log("Response Data", response.data);
        setselectTimeSlotRes(response.data);
        setadultfare(response.data.adultPrice); //b2bChildDisPrice
        setchildfare(response.data.childPrice);
        //setagentServiceTicketTypes(response.data.agentServiceTicketTypes);
        setshowTktFare(1);
      })
      .catch((error) => {
        console.log(error);
      });

    setbookTimeSlot(startDateTime);
    // console.log(startDateTime);
    // console.log(eventID);
    // let selectedTime = timeSlot.filter(
    //   (timeslot) => timeslot.eventID == eventID
    // );
    // console.log(selectedTime);
    // setadultfare(selectedTime[0].adultPrice); //b2bChildDisPrice
    // setchildfare(selectedTime[0].childPrice);
    // setshowTktFare(1);
  };
  const submitValidation = values => {
    // console.log( "submitValidation:::", values);
    setinsuffBal(1);
    const total = adultfare * numOfAdult +  childfare * numOfChild + addonPrice
    // console.log( "total:::", total);
    // console.log( "agentDetails.currentBalance:::", agentDetails.currentBalance);
    if(total >= agentDetails.currentBalance) {
      // console.log( "submitValidation if:::", agentDetails.currentBalance);
      setinsuffBal(1);
    } else {
      // console.log( "submitValidation else:::", agentDetails.currentBalance);
      setinsuffBal(0);
      handleSubmitTest(values);
    }
  }
  const handleSubmitTest = (values) => {
    let child_count;
    if (!values.bookNofChild) {
      child_count = 0;
    } else {
      child_count = values.bookNofChild;
    }
    let total = adultfare * numOfAdult + childfare * child_count + addonPrice;

    // console.log( "after added percentage", (2.84/100)*total);
    // console.log("addesd", total+((2.84/100)*total));

    if (LoggedIn == 1) {
      if (values.bookPaymentMode == 1) {
        //  console.log("total-amount",total+((2.84/100)*total) )
        total = total + (2.84 / 100) * total;
        // total = total - 100 * 100;
      }
    }

    //  console.log(" selfAgentTicket====", selfAgentTicket);
    //  console.log(" agency.agentName====", agency);
    //  console.log(" agency.agentName====", agencyuser);
    //  console.log(" agency.userdata====", userdata);
    const submitdata = {
      bookB2bId: agency,
      bookB2bUserId: agencyuser,
      bookB2cId: userId,
      attractionsId: values.attractionsId,
      bookCustomerName: selfAgentTicket == '0' ? values.bookCustomerName : userdata.agency.agencyName,
      bookCustomerEmail: values.bookCustomerEmail,
      bookMobileNumber: values.bookMobileNumber,
      ticketTypeId: values.ticketTypeId,
      bookNofAdult: values.bookNofAdult,
      bookNofChild: child_count,
      bookAdultPrice: adultfare,
      bookChildPrice: childfare,
      bookTravellDate: values.bookTravellDate,
      bookPaymentMode: values.bookPaymentMode,
      bookTotal: total,
      bookingAddon: bookingAddon,
    };
    // console.log(" submitdata====", submitdata);

    encryptStorage.setItem("DFuck", submitdata);

    //encryptStorage.setItem("wps", OpenTicket);
    let api_paymenyt;
    if (tourpack.attConnectWithApi == true) {
      if (OpenTicket == 2) {
        api_paymenyt = 2;
      }
    } else {
      api_paymenyt = 1;
    }

    const apiTicketPayment = {
      OpenTickets: api_paymenyt,
    };

    //console.log("Api de  -- 1", apiTicketPayment)
    encryptStorage.setItem("suckapi", apiTicketPayment);
    //console.log("Api de  -- 2", apiTicketPayment)

    // console.log(submitdata);
    //Open Ticket(1) --local(old Methed) Dated ticket(2) -- from Api(New Methed)
    if (LoggedIn == 1) {
      // console.log("logged In");
      if (OpenTicket == 1 || OpenTicket == "") {
        // console.log("Open Ticket");
        if (values.bookPaymentMode == 1) {
          //  ? console.log("Online payment");
          const paymentData = {
            paymentAgencyId: agency,
            paymentAgencyUserId: agencyuser,
            paymentB2cId: userId,
            paymentCustomerName: userName,
            paymentRemarks: "Test Pay",
            paymentAmount: total,
          };
          // console.log("Submit Data", paymentData);

          // console.log("booking confirmed");
          axios
            .post(requests.getPaymentGatewayUrl, paymentData)
            .then((response) => {
              encryptStorage.setItem("url", response.data);
              setshowAlert(0);
              history.push("/online-payment-port");
              setshowAlert(0);
            })
            .catch((error) => {
              console.log(error);
              setshowAlert(0);
            });
        } else {
          setshowAlert(1);

          // console.log("booking confirmed");
          axios
            .post(requests.postBooking, submitdata)
            .then((res) => {
              //no adult tkt
              if (res.data.errorCode == 505) {
                //no adult tkt
                setshowAlert(2);
              } else if (res.data.errorCode == 504) {
                // no child tkt
                setshowAlert(3);
              } else if (res.data.errorCode == 303) {
                setshowAlert(4);
              } else if (res.data.errorCode == 100) {
                setinsuffBal(1);
                setshowAlert(0);
              } else {
                const fileURL =
                  FILE_STORE_BASE_URL +
                  res.data.bookingTickPdfPath;
                const filename = res.data.bookingTickPdfPath;

                Swal.fire({
                  title: "Booking Confirmed",
                  text: "Thank You For Your Booking, press OK to download your e-Ticket",
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: "Ok",
                  denyButtonText: `Don't save`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    download_file(fileURL, filename);
                    setshowAlert(0);
                  }
                });
                setshowAlert(0);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        const firstSubmitForBooking = {
          attractionId: values.attractionsId,
          agencyId: agency,
          agencyUserId: agencyuser,
          customerName: values.bookCustomerName,
          customerEmail: values.bookCustomerEmail,
          bookingDate: values.bookTravellDate,
          eventId: selectTimeSlotRes.eventId,
          eventName: selectTimeSlotRes.eventName,
          eventTypeId: selectTimeSlotRes.eventTypeId,
          resourceId: selectTimeSlotRes.resourceId,
          startDateTime: selectTimeSlotRes.startDateTime,
          endDateTime: selectTimeSlotRes.endDateTime,
          available: selectTimeSlotRes.available,
          status: selectTimeSlotRes.status,
          adultPrice: selectTimeSlotRes.adultPrice,
          childPrice: selectTimeSlotRes.childPrice,
          nofAdult: values.bookNofAdult,
          nofChild: child_count,
          nofInfant: "",
          agentServiceTicketTypes: selectTimeSlotRes.agentServiceTicketTypes,
          totalPrice: total,
          bookPaymentMode: values.bookPaymentMode,
        };

        encryptStorage.setItem("firstSubmitForBooking", firstSubmitForBooking);

        // for booking dated ticket (from api)
        // console.log("dated Ticket");
        // here need to check payment mode is online(1) or credit(2)
        if (values.bookPaymentMode == 1) {
          //here registerfor payment and get payment registeri id
          // console.log("Online Payment dated Ticket");
          const paymentData = {
            paymentAgencyId: agency,
            paymentAgencyUserId: agencyuser,
            paymentB2cId: userId,
            paymentCustomerName: userName,
            paymentRemarks: "Test Pay",
            paymentAmount: total,
          };
          //getPaymentGatewayUrl
          axios
            .post(requests.getPaymentGatewayUrl, paymentData)
            .then((response) => {
              // console.log(response.data);
              encryptStorage.setItem("url", response.data);

              history.push("/dated-ticket-payment");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          // console.log("Submit Data", firstSubmitForBooking);
          axios
            .post(requests.saveBurjTicket, firstSubmitForBooking)
            .then((response) => {
              // console.log(response.data);
              encryptStorage.setItem("url", response.data);
              history.push("/api-tkt-confirmation-page");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    } else {
      //user process
      if (tourpack.attConnectWithApi == true) {
        if (OpenTicket == 2) {
          const firstSubmitForBooking = {
            attractionId: values.attractionsId,
            agencyId: agency,
            agencyUserId: agencyuser,
            customerName: values.bookCustomerName,
            customerEmail: values.bookCustomerEmail,
            bookingDate: values.bookTravellDate,
            eventId: selectTimeSlotRes.eventId,
            eventName: selectTimeSlotRes.eventName,
            eventTypeId: selectTimeSlotRes.eventTypeId,
            resourceId: selectTimeSlotRes.resourceId,
            startDateTime: selectTimeSlotRes.startDateTime,
            endDateTime: selectTimeSlotRes.endDateTime,
            available: selectTimeSlotRes.available,
            status: selectTimeSlotRes.status,
            adultPrice: selectTimeSlotRes.adultPrice,
            childPrice: selectTimeSlotRes.childPrice,
            nofAdult: values.bookNofAdult,
            nofChild: child_count,
            nofInfant: "",
            agentServiceTicketTypes: selectTimeSlotRes.agentServiceTicketTypes,
            totalPrice: total,
            bookPaymentMode: values.bookPaymentMode,
          };
          // console.log("Api Ticket Paytnmentsdsdsdsds");
          // console.log("Api Ticket Paytnmentsdsdsdsds", apiTicketPayment);
          encryptStorage.setItem(
            "firstSubmitForBooking",
            firstSubmitForBooking
          );
          const paymentData = {
            paymentAgencyId: agency,
            paymentAgencyUserId: agencyuser,
            paymentB2cId: userId,
            paymentCustomerName: userName,
            paymentRemarks: "Test Pay",
            paymentAmount: total,
          };
          //getPaymentGatewayUrl
          axios
            .post(requests.getPaymentGatewayUrl, paymentData)
            .then((response) => {
              // console.log(response.data);
              encryptStorage.setItem("url", response.data);

              history.push("/dated-ticket-payment");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          const paymentData = {
            paymentAgencyId: agency,
            paymentAgencyUserId: agencyuser,
            paymentB2cId: userId,
            paymentCustomerName: userName,
            paymentRemarks: "Test Pay",
            paymentAmount: total,
          };
          //history.push("/login-page");
          // console.log("Submit Data", paymentData);
          axios
            .post(requests.getPaymentGatewayUrl, paymentData)
            .then((response) => {
              // console.log("Url Response", response.data);
              encryptStorage.setItem("url", response.data);
              setshowAlert(0);
              history.push("/online-payment-port"); //api-tkt-confirmation-page
              // history.push("/api-tkt-confirmation-page");
              setshowAlert(0);
            })
            .catch((error) => {
              console.log(error);
              setshowAlert(0);
            });
        }
      } else {
        const paymentData = {
          paymentAgencyId: agency,
          paymentAgencyUserId: agencyuser,
          paymentB2cId: userId,
          paymentCustomerName: userName,
          paymentRemarks: "Test Pay",
          paymentAmount: total,
        };
        //history.push("/login-page");
        // console.log("Submit Data", paymentData);
        axios
          .post(requests.getPaymentGatewayUrl, paymentData)
          .then((response) => {
            // console.log("Url Response", response.data);
            encryptStorage.setItem("url", response.data);
            setshowAlert(0);
            history.push("/online-payment-port"); //api-tkt-confirmation-page
            // history.push("/api-tkt-confirmation-page");
            setshowAlert(0);
          })
          .catch((error) => {
            console.log(error);
            setshowAlert(0);
          });
      }
    }
  };

  const successAlert = (title, text, url, boolValue) => {
    Swal.fire({
      title: title,
      text: text,
      imageUrl: url,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      showCancelButton: false, // There won't be any cancel button
      showConfirmButton: boolValue, // There won't be any confirm button
    });
  };

  if (showAlert == 1) {
    successAlert(
      "Please Wait",
      "Processing",
      publicUrl + "assets/img/formsubmit.gif"
      //publicUrl publicUrl + "assets/img/others/about1.jpeg"
    );
  }
  if (showAlert == 2) {
    successAlert("Ticket Not Avilable", "Ticket Not Available");
  }

  if (showAlert == 3) {
    successAlert("Ticket Not Avilable", "Child  Ticket Not Available");
  }
  if (showAlert == 4) {
    successAlert("Technical Problem", "Something went wrong");
  }
  /* Helper function */
  function download_file(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }
  
  return (
    <>
      <TopNav />
      {/* <DynamicBanner
        imgUrl={requests.imgpath + tourpack.attBannerImage}
        title={tourpack.attName}
      /> */}
                    <Carousel
                      // showArrows={true}
                      // showThumbs={true}
                      // transitionTime={10000}
                      autoPlay={true}
                      infiniteLoop={true}
                    >
                      {!isLoading && tourpack.filesStorage.map((tourImage, index) => (
                        // slice(0,10 )
                        <div
                          className="tp-gallery-item col-lg-12 "
                          // className="carousal-img-size"
                          key={index}
                        >
                          <div className="tp-gallery-item-img">
                            <a href="#" data-effect="mfp-zoom-in">
                              <img
                                height="300"
                                width="100%"
                                src={requests.imgpath + tourImage.fileName}
                                alt="image"
                              />
                            </a>
                          </div>
                        </div>
                      ))}
                    </Carousel>
      <div className="tour-details-area mg-top--150">
        <div className="tour-details-gallery">
          <div className="container-bg bg-dark-blue">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-lg-4">
                  <div className="details">
                    <p className="location mb-0">
                      <i className="fa fa-map-marker" />
                      {tourpack.attCity}
                    </p>
                    <h4 className="title">{tourpack.attName}</h4>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8">
                  <div className="book-list-warp">
                    <p className="book-list-content">
                      Just booked! Get your spot before it's too late.
                    </p>
                    <div className="tp-price-meta">
                      {/* <p>Price</p>
                      <h2>
                        {LoggedIn == 1
                          ? tourpack.b2bAdultPrice
                          : tourpack.b2cAdultPrice}
                        &nbsp;&nbsp;&nbsp;
                        <small>AED</small>
                      </h2> */}

<Row style={{marginLeft: '-1%'}}>
                        <Col>
                          {/* <p>
                          <div className="priceColorWhite">Adult Price From</div> <h2>{tourpack.adultPrice}</h2>
                          </p>{" "}
                          &nbsp;&nbsp;&nbsp;<small>AED</small> */}
                          <p><div className="priceColorWhite">Adult Price From</div></p>
                        <h2>{tourpack.adultPrice}  &nbsp;&nbsp;&nbsp;
                        <small id='attractionAed'>AED</small></h2>
                        </Col>
                        <Col>
                          {/* <p>
                          <div className="priceColorWhite">Child Price From</div> <br />
                            <h2>{tourpack.childPrice}</h2>
                          </p>{" "}
                          &nbsp;&nbsp;&nbsp;<small>AED</small> */}
                          <p><div className="priceColorWhite">Child Price From</div></p>
                        <h2>{tourpack.childPrice}  &nbsp;&nbsp;&nbsp;
                        <small id='attractionAed'>AED</small></h2>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <ul className="tp-list-meta border-tp-solid">
                    {isBannerLoading ? (
                      <>Loading ...</>
                    ) : (
                      <>
                        {bannerAddOn.map((banner, index) => (
                          <li key={index}>
                            <i className={banner.addonIcon} />{" "}
                            {banner.addonType} &nbsp; - &nbsp;
                            {banner.addonValue}
                          </li>
                        ))}
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {isLoading ? (
            <>Loading...</>
          ) : (
            <>
              <Row>
                {/* <Col sm={36}> */}
                  {/* <div style={{height: '30vh', width: '100vh'}}> */}
                  <div style={{width: '100%'}}>
                <MainListCombo attractions={tourpack}/>
                </div>
                {/* </Col> */}
                </Row>
                <CardBody>
                   

                    <div className="tour-details-wrap">
                      <h4 className="single-page-small-title">
                        {tourpack.attName}
                      </h4>
                      <p>
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: tourpack.attDescription,
                          }}
                        />
                      </p>
                    </div>
                  </CardBody>
                </>)}
      </div>
      </div>

      <div>
        <div className="container">
          <h4 className="single-page-small-title">You May Also Like</h4>
          <Attractionbygroup attList={attractionList} />
        </div>
      </div>
      <Subscribe />
      <Footer />
    </>
  );
};

// ComboPackdetail= connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ComboPackdetail);
export default reduxForm({
  form: "TicketBookingForm",
  // asyncBlurFields: ["bookCustomerName"],
  validate,
  onSubmitSuccess: AfterSubmit,
})(ComboPackdetail);
