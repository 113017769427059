import ComboPackdetail from "../combo-tour-pack-detail/combo_pack_details";

const ComboTourPackDetail = () => {
  return (
    <>
      <ComboPackdetail />
    </>
  );
};

export default ComboTourPackDetail;
