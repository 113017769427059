import { React,  useEffect, useState} from "react";
import "./userProfile.css"
import axios from "axios";
import requests from "../../utils/Requests";
import {IMAGES_PATH_URL} from "../../utils/constants";
import encryptStorage from "../../utils/Encryptstorage";
export const ImgUpload =({
    onChange,
    src
  })=>
    <label htmlFor="photo-upload" className="custom-file-upload fas">
      <div className="img-wrap img-upload" >
        <img id='profile'for="photo-upload" src={src}/>
      </div>
      <input id="photo-upload" type="file" onChange={onChange}/> 
    </label>
  
  
  export const Name =({
    onChange,
    value
  })=>
    <div className="field">
      <label>
        name:
      </label>
      <label htmlFor="name">
        {value}
      </label>
      {/* <input 
        id="name" 
        type="text" 
        onChange={onChange} 
        maxlength="25" 
        value={value} 
        placeholder="Alexa" 
        required/> */}
    </div>
  
    
  export const Status =({
    onChange,
    value
  })=>
    <div className="field">
      <label htmlFor="status">
        status:
      </label>
      <input 
        id="status" 
        type="text" 
        onChange={onChange} 
        maxLength="35" 
        value={value} 
        placeholder="It's a nice day!" 
        required/>
    </div>
  
  
  export const Profile =({
    onSubmit,
    src,
    name,
    status,
  })=>
    <div className="cardProfile">
      <form id="profileForm" onSubmit={onSubmit}>
        <h3>Profile Card</h3>
        <label className="custom-file-upload fas">
          <div className="img-wrap" >
            <img for="photo-upload" src={src}/>
          </div>
        </label>
        <div className="name">{name}</div>
        <button id="profileButton" type="submit" className="edit">Edit Profile </button>
      </form>
    </div>
       
        
       export const Edit =({
    onSubmit,
    children,
  })=>
    <div className="card">
      <form id="profileForm" onSubmit={onSubmit}>
        <h3>Profile Card</h3>
          {children}
        <button id="profileButton" type="submit" className="save">Save </button>
      </form>
    </div>
  
//   export default class CardProfile extends React.Component {
//     constructor(props) {
//         super(props);
//     console.log('props:::', props)
     
    
//     this.state = {
//       file: '',
//       imagePreviewUrl: '',
//       name:'',
//       status:'',
//       active: 'profile',
//       fileName: null
//     }
// }
  
    
    // render() {
    //   const {imagePreviewUrl, 
    //          name, 
    //          status, 
    //          active} = this.state;
    //          const imageFile = fileName ? fileName : props.userDetails ? 
    //          props.userDetails.profilePhoto : null
    //   return (
    //     <div>
    //       {(active === 'edit')?(
    //         <Edit onSubmit={this.handleSubmit}>
    //           <ImgUpload onChange={this.photoUpload} src={imagePreviewUrl}/>
    //           {/* <Name onChange={this.editName} value={name}/> */}
    //           <Name value={props.name}/>
    //           {/* <Status onChange={this.editStatus} value={status}/> */}
    //         </Edit>
    //       ):(
    //         imageFile &&
    //         <Profile 
    //           onSubmit={this.handleSubmit} 
    //         //   src={imagePreviewUrl} 
    //         src={IMAGES_PATH_URL + "profile/"+ imageFile}
    //           name={props.name} 
    //           status={status}/>)}
          
    //     </div>
    //   )
    // }
  // }
  const CardProfile = (props) => {

    const photoUpload = e =>{
      e.preventDefault();
      const reader = new FileReader();
      const file = e.target.files[0];
    //   console.log('file:::', file)
      reader.onloadend = () => {
        setfile(file)
        setimagePreviewUrl(reader.result)
        // this.setState({
        //   file: file,
        //   imagePreviewUrl: reader.result
        // });
    //   console.log('reader.result:::', reader.result)
      }
      reader.readAsDataURL(file);
    //   seterrorMsg("");
        // const file = e.target.files[0];
        const formdata = new FormData();
        formdata.append("file", file);
    
        axios
          .post(requests.fileUploadProfile, formdata)
          .then((res) => {
            console.log('res.fileName:::', res.data.fileName)
            setfileName(res.data.fileName)
            setprofilePhoto(res.data.fileName)
            // this.setState({
            //     fileName : res.data.fileName
            // })
          })
          .catch((err) => {
            console.log("err", err);
          });
    }
    
    // const editName = e =>{
    //   const name = e.target.value;
    //   console.log('name:::', name)
    //   this.setState({
    //     name,
    //   });
    // }
    
    // const editStatus = e => {
    //   const status = e.target.value;
    //   setstatus(status)
    // }
    
    const handleSubmit= e =>{
        console.log('fileName:::', fileName)
        console.log('his.props.LoggedIn :::', props.loginUser )
        let url = ''
        if(fileName) {
        const enl = encryptStorage.getItem("enl"); //obj //agency.agencyId
      let urlObject = JSON.parse(enl);
        if(props.loginUser === 1) {
          urlObject.agency.profilePhoto = fileName
            url = requests.updateAgencyDetails
        } else if(props.loginUser === 2) {
            url = requests.setagencyuser
            urlObject.agencyUser.profilePhoto = fileName
        }else if(props.loginUser === 3) {
            url = requests.setcustomer
            urlObject.customer.profilePhoto = fileName
        }
        encryptStorage.setItem("enl", urlObject)
        let userDetails = props.userDetails
        userDetails.profilePhoto = fileName
        axios
          .post(url, props.userDetails)
          .then((res) => {
            console.log('res:::', res)
            // window.location.reload(false)
            // this.setState({
            //     fileName : res.data.fileName
            // })
          })
          .catch((err) => {
            console.log("err", err);
          });
        }
        let activeP = active === 'edit' ? 'profile' : 'edit';
        // this.setState({
        //   active: activeP,
        // })
        setactive(activeP)
        e.preventDefault();
    }
    const [status, setstatus] = useState('');
    const [file, setfile] = useState('');
    const [imagePreviewUrl, setimagePreviewUrl] = useState('');
    const [name, setname] = useState('');
    const [active, setactive] = useState('profile');
    const [fileName, setfileName] = useState('');
    const [profilePhoto, setprofilePhoto] = useState('');
    useEffect(() => {
      setprofilePhoto(props.userDetails.profilePhoto)
      setimagePreviewUrl(IMAGES_PATH_URL + "profile/"+ props.userDetails.profilePhoto)
    }, [props.userDetails])
    
    return (
      <>
          {active === 'edit' ?(
            <Edit onSubmit={handleSubmit}>
              <ImgUpload onChange={photoUpload} src={imagePreviewUrl}/>
              {/* <Name onChange={this.editName} value={name}/> */}
              <Name value={props.name}/>
              {/* <Status onChange={this.editStatus} value={status}/> */}
            </Edit>
          ):(
            <Profile 
              onSubmit={handleSubmit} 
            //   src={imagePreviewUrl} 
            src={IMAGES_PATH_URL + "profile/"+ profilePhoto}
              name={props.name} 
              status={status}/>)}
          
      </>
    );
  };
  export default CardProfile;
  