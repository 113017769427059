import CommonBanner from "./global-components/common_banner";
import Footer from "./global-components/footer";
import Subscribe from "./global-components/subscribe";
import TopNav from "./global-components/top_nav";
import ComboTourorderlist from "./combo_tour-list/combo_tour_order_list";
const Combo = () => {
  return (
    <>
    <TopNav/>
      <CommonBanner title="Combo" imgUrl ="attBanner.jpeg"/>
      <ComboTourorderlist />
      <Subscribe/>
      <Footer/>
    </>
  );
};

export default Combo;
