import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { Col, Row, Button, Card, CardBody, Label, Alert } from "reactstrap";
import { Field, Form, reduxForm } from "redux-form";
import RenderField from "../../form-components/RenderField";
import encryptStorage from "../../utils/Encryptstorage";
import requests from "../../utils/Requests";
import { IMAGES_PATH_URL } from "../../utils/constants";

const validate = (values) => {};

const VisaForm = (props) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  let history = useHistory();

  // private int visaEnquiryId;
  // private int visaId;
  // private String firstName;
  // private String middleName;
  // private String lastName;
  // private String nationality;
  // private String passportNumber;
  // private Date dateOfBirth;
  // private String gender;
  // private Date passportIssueDate;
  // private Date passportExpiryDate;
  // private String birthPlace;
  // @Column(nullable = true)
  // private String birthCity;
  // private String birthCountry;
  // private String passportCountry;
  // private String city;
  // private String phoneNumber;
  // @Column(nullable = true)
  // private String alternateNumber;
  // @Column(nullable = true)
  // private String eMail;
  const [visaData, setvisaData] = useState([]);
  useEffect(() => {
    let visadata = encryptStorage.getItem("asiv");
    let visaObject = JSON.parse(visadata);
    console.log("visa Data", visaObject);
    setvisaData(visaObject);
  }, []);
  const [passportFront, setpassportFront] = useState("");
  const [passportBack, setpassportBack] = useState("");
  const [userImage, setuserImage] = useState("");
  const [errorMsg, seterrorMsg] = useState("");

  const genderData = [
    { name: "Gender", value: "" },
    { name: "Male", value: "Male" },
    { name: "Female", value: "Female" },
    { name: "Others", value: "Others" },
  ];

  const uploadImage = (e, name) => {
    seterrorMsg("");
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("file", file);

    axios
      .post(requests.getFileUpload, formdata)
      .then((res) => {
        // console.log("response", res.data); //fileName
        if (name == 1) {
          setpassportFront(res.data.fileName);
        } else if (name == 2) {
          setpassportBack(res.data.fileName);
        } else {
          setuserImage(res.data.fileName);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const sumbittoAPI = (values) => {
    seterrorMsg("");
    if (passportFront == "" || passportBack == "" || userImage == "") {
      if (passportFront == "") {
        seterrorMsg("Upload Image Properly");
      }
      if (passportBack == "") {
        seterrorMsg("Upload Image Properly");
      }
      if (userImage == "") {
        seterrorMsg("Upload Image Properly");
      }
    } else {
      const submitData = {
        firstName: values.fName,
        middleName: values.mName,
        lastName: values.lName,
        nationality: values.nationality,
        passportNumber: values.passportNumber,
        dateOfBirth: values.dob,
        gender: values.gender,
        passportIssueDate: values.passportIssueDate,
        passportExpiryDate: values.passportExpiryDate,
        birthCity: values.birthCity,
        birthCountry: values.birthCountry,
        passportCountry: values.passportCountry,
        phoneNumber: values.phoneNumber,
        alternateNumber: values.alternateNumber,
        eMail: values.eMail,
        visaType: visaData.name,
        withInsurance: visaData.insurance,
        visaPrice: visaData.price,
      };
      console.log("Form Submit", submitData);
    }
  };

  return (
    <>
      {" "}
      <Form onSubmit={handleSubmit(sumbittoAPI.bind(this))}>
        <Row>
          <Col xs={4}>
            <Field
              name="fName"
              type="text"
              icon=""
              label="First Name"
              component={RenderField.RenderTextField}
            />
          </Col>

          <Col xs={4}>
            <Field
              name="mName"
              type="text"
              icon=""
              label="Middle Name"
              component={RenderField.RenderTextField}
            />
          </Col>

          <Col xs={4}>
            <Field
              name="lName"
              type="text"
              icon=""
              label="Last Name"
              component={RenderField.RenderTextField}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Field
              name="dob"
              type="date"
              icon=""
              label="Date Of Birth"
              component={RenderField.RenderTextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="gender"
              type="select"
              customfeild={genderData}
              label="Gender"
              component={RenderField.renderOptionField}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="nationality"
              type="text"
              icon=""
              label="Nationality"
              component={RenderField.RenderTextField}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Field
              name="passportNumber"
              type="text"
              icon=""
              label="Passport Number"
              component={RenderField.RenderTextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="birthCity"
              type="text"
              icon=""
              label="Birth City"
              component={RenderField.RenderTextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="birthCountry"
              type="text"
              icon=""
              label="Birth Country"
              component={RenderField.RenderTextField}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <Field
              name="passportCountry"
              type="text"
              icon=""
              label="Passport Issue Country"
              component={RenderField.RenderTextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="passportIssueDate"
              type="date"
              icon=""
              label="Passport Issue Date"
              component={RenderField.RenderTextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="passportExpiryDate"
              type="date"
              icon=""
              label="Passport Expiry Date"
              component={RenderField.RenderTextField}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <Field
              name="phoneNumber"
              type="text"
              icon=""
              label="Contact Number"
              component={RenderField.RenderTextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="contactNumber2"
              type="text"
              icon=""
              label="Additional Number"
              component={RenderField.RenderTextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="email"
              type="email"
              icon=""
              label="E-Mail"
              component={RenderField.RenderTextField}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <div className="single-widget-search-input-title">
              Passport Front Side
            </div>

            <input type="file" onChange={(e) => uploadImage(e, 1)} />

            {passportFront == "" ? null : (
              <img
                src={
                  IMAGES_PATH_URL +
                  passportFront
                }
              />
            )}
          </Col>
          <Col xs={4}>
            <div className="single-widget-search-input-title">
              Passport Back Side
            </div>

            <input type="file" onChange={(e) => uploadImage(e, 2)} />
            {passportBack == "" ? null : (
              <img
                src={
                  IMAGES_PATH_URL +
                  passportBack
                }
              />
            )}
          </Col>
          <Col xs={4}>
            <div className="single-widget-search-input-title">
              Applicant Image
            </div>

            <input type="file" onChange={(e) => uploadImage(e, 3)} />
            {userImage == "" ? null : (
              <img
                src={
                  IMAGES_PATH_URL +
                  userImage
                }
              />
            )}
          </Col>
        </Row>
        <br />

        {errorMsg == "" ? null : <Alert color="danger">{errorMsg}</Alert>}

        <div className="text-lg-center text-left">
          <Button
            color="primary"
            type="submit"
            class="btn btn-yellow"
            disabled={submitting}
          >
            Book Now
          </Button>
        </div>
      </Form>
    </>
  );
};

export default reduxForm({
  form: "VisaBookingform",
  validate,
})(VisaForm);
