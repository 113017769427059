import { Input, FormGroup } from "reactstrap";
import Switch from "react-switch";
import { PhoneInputField } from "./PhoneInputField";
const RenderTextField = ({
  input,
  label,
  type,
  icon,
  meta: { asyncValidating, touched, error },
}) => (
  <>
    <div className="single-widget-search-input-title">
      <i className={icon} /> {label} 
    </div>
    <div className={asyncValidating ? "async-validating" : ""}>
      <Input {...input} type={type} />
     
    </div>
    <div className="err-msg">
    {touched && error && <span >{error}</span>}

    </div>
   
  </>
);
const RenderTextFieldWithSelect = ({
  input,
  label,
  type,
  icon,
  meta: { asyncValidating, touched, error },
}) => (
  <>
    <div className="single-widget-search-input-title">
      <i className={icon} /> {label} 
    </div>
    <div className={asyncValidating ? "async-validating" : ""}>
      <Input {...input} type={type} />
     
    </div>
    <div className="err-msg">
    {touched && error && <span >{error}</span>}

    </div>
   
  </>
);
const RenderLabelField = ({
  input,
  label,
  type,
  icon,
  meta: { asyncValidating, touched, error },
}) => (
  <> 
  <div className="single-widget-search-input-title">
      <i className={icon} /> {label} 
    </div>
    <div className="err-msg">
    {touched && error && <span >{error}</span>}
    </div>  
  </>
);
const renderOptionField = ({
  input,
  label,
  type,
  customfeild,
  icon,
  meta: { asyncValidating, touched, error },
}) => (
  <>
    <div className="single-widget-search-input-title">
      <i className={icon} /> {label}
    </div>
    <Input {...input} type={type} placeholder={label}>
      {customfeild.map((optvalue, key) => (
        <option key={key} name={optvalue.value} value={optvalue.value}>
          {optvalue.name}
        </option>
      ))}
    </Input>
    <div className="err-msg">
    {touched && error && <span >{error}</span>}

    </div>
  </>
);

const renderRadioButton = ({
  input,
  label,
  type,
  icon,
  customfeild,
  meta: { asyncValidating, touched, error },
}) => (
  <div>
    <div className={asyncValidating ? "async-validating" : ""}>
      {/* <Label for={label}>{label}</Label> */}
      {}
      <Input {...input} type={type} name={label} value={customfeild} />
      {label}
      
    </div>
    <div className="err-msg">
    {touched && error && <span >{error}</span>}

    </div>
  </div>
);

const renderDisabledField = ({
  input,
  label,
  type,
  icon,
  customfeild,
  meta: { asyncValidating, touched, error },
}) => (
  <div>
    <div className={asyncValidating ? "async-validating" : ""}>
      {/* <Label for={label}>{label}</Label> */}
      {}
      <Input {...input} type={type} name={label} value={customfeild}  disabled/>
      {label}
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

const RendercheckboxField = ({
  input,
  label,
  type,
  customfeild,
  meta: { asyncValidating, touched, error },
}) => (
  <div>
    <FormGroup check>
   {/* <Input  {...input} type={type} name={label} value={customfeild} /> */}
    <Switch  {...input} type={type}  />
      {label}
      {touched && error && <span>{error}</span>}
    </FormGroup>

  </div>
);
const RendePhoneNumberField = ({
  input,
  label,
  type,
  icon,
  meta: { asyncValidating, touched, error },
}) => (
  <>
    <div className="single-widget-search-input-title">
      <i className={icon} /> {label} 
    </div>
    <div className={asyncValidating ? "async-validating" : ""}>
      {/* <Input {...input} type={type} /> */}
      <PhoneInputField {...input} type={type} />
     
    </div>
    <div className="err-msg">
    {touched && error && <span >{error}</span>}

    </div>
   
  </>
);


export default { RenderTextField,RenderTextFieldWithSelect, renderOptionField, renderRadioButton , 
  renderDisabledField,  RendercheckboxField, RenderLabelField, RendePhoneNumberField};
